import i18n from "i18next";

type FixedAlign = boolean | "left" | "right";
export const alignRight: FixedAlign = "right";

type OrderType = "descend" | "ascend" | undefined;
export const order: OrderType = "ascend";

export const formValidateTriggers = {
  validateTrigger: ["onBlur"],
};
export const dateFormat = "DD/MM/YYYY";

export const dateTimeFormat = "DD/MM/YYYY à HH:mm";

export const authRedirectKey = "authRedirect";

export const DEFAULT_500_ERROR = {
  responseCode: 500,
  errorMessage: i18n.t("error.500"),
  data: undefined,
  ok: false,
};

export const DEFAULT_401_ERROR = {
  responseCode: 401,
  errorMessage: i18n.t("error.401"),
  data: undefined,
  ok: false,
};

export const emptySearchResult = {
  numberOfElements: 0,
  totalElements: 0,
  size: 0,
  totalPages: 0,
  number: 0,
  content: [],
};

export const FIELD_LENGTH = {
  URL: 500,
  DESCRIPTION: 255,
  NAME: 200,
  TITLE: 2000,
  TEXTAREA: 5000,
  MAIL: 255,
  PHONE_NUMBER: 20,
};

export const LOCAL_STORAGE = {
  COOKIE: "COOKIE",
};

export const MAX_UNSUCCESSFUL_ATTEMPTS = 5;
export const LOGIN_BLOCK_PERIOD_IN_MINUTES = 1;

export const CALLBACK_URL_PARAM = "c";
export const EMAIL_URL_PARAM = "email";
export const OTP_URL_PARAM = "otp";
export const REGISTRATION_PARAM = "register";
export const REGISTRATION_PROFILE_PARAM = "profile";

export const IMG_DEFAULT_ALT = "";
export const LINK_DEFAULT_TITLE = "";

export const CARD_FLIP_BACK_DURATION = 3000;
