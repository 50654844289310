import { FunctionComponent, JSX } from "react";
import IconAlert from "@static/core/img/icon-alert.svg?react";

interface BasicAlertProps {
  variant?: string;
  icon?: JSX.Element;
  text: string | JSX.Element;
  className?: string;
}

const BasicAlert: FunctionComponent<BasicAlertProps> = ({
  variant = "",
  icon = <IconAlert />,
  text,
  className = "",
}) => {
  return (
    <div className={`app-alert alert-${variant} ${className}`}>
      <div className="icon">{icon}</div>
      <h4 className="text">{text}</h4>
    </div>
  );
};

export default BasicAlert;
