import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Pageable } from "@type/core/list/pagination.types";
import { AuthorizationDetailResponseDto } from "@state/authorization/response/authorization.detail.response.dto";
import Section from "@components/core/section/Section";
import { Spin } from "antd";
import BasicTable from "@components/core/tables/BasicTable";
import { columns as myAutorisationsColumns } from "@views/core/admin/licenses/columns/MyAutorisationsColumns";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import {
  requestAuthorizeVeterinarySoftware,
  requestBlockVeterinarySoftware,
  requestGetUserAuthorizations,
} from "@state/authorization/AuthorizationEffects";
import { toastError } from "@utils/helpers/toast-helper";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";
import { UserProfile } from "@utils/enums/profile.enum";

const MyAuthorizations: FunctionComponent = () => {
  const { t } = useTranslation();
  const spinIcon = <LoadingOutlined spin />;

  const authenticationContext = useUnit(authenticationStore);

  const searchAuthorizationsAllowed =
    authenticationContext?.user?.profiles.includes(UserProfile.VETERINARY);

  const [limit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [myAuthorizationsLoaded, setMyAuthorizationsLoaded] =
    useState<boolean>(true);

  const [myAuthorizations, setMyAuthorizations] = useState<
    Pageable<AuthorizationDetailResponseDto>
  >({
    size: limit,
    totalElements: 0,
    content: [],
    numberOfElements: 0,
    totalPages: 0,
    number: 0,
  });

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const fetchMyAuthorizations = useCallback(() => {
    if (searchAuthorizationsAllowed) {
      setMyAuthorizationsLoaded(false);
      requestGetUserAuthorizations({
        page: page - 1,
        limit,
      })
        .catch(() => {
          toastError(t("users.list.messages.loading-error"));
        })
        .finally(() => {
          setMyAuthorizationsLoaded(true);
        });
    }
  }, [searchAuthorizationsAllowed, page, limit, t]);

  useEffect(() => {
    fetchMyAuthorizations();
  }, [fetchMyAuthorizations]);

  useEffect(() => {
    return requestGetUserAuthorizations.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMyAuthorizations(result.data);
      }
    });
  }, []);

  useEffect(() => {
    return requestBlockVeterinarySoftware.done.watch(({ result }) => {
      if (result.ok) {
        fetchMyAuthorizations();
      }
    });
  });

  useEffect(() => {
    return requestAuthorizeVeterinarySoftware.done.watch(({ result }) => {
      if (result.ok) {
        fetchMyAuthorizations();
      }
    });
  });

  return (
    <Section
      title={t("authorizations.myAutorisations.title")}
      description={t("authorizations.myAutorisations.description")}
    >
      {!myAuthorizationsLoaded ? (
        <div className="text-center">
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <BasicTable
          dataSource={myAuthorizations.content}
          columns={myAutorisationsColumns()}
          pagination={{
            total: myAuthorizations.totalElements,
            pageSize: myAuthorizations.size,
            current: myAuthorizations.number + 1,
            onChange: (pageNumber: number) => {
              handlePageChange(pageNumber);
            },
          }}
        />
      )}
    </Section>
  );
};

export default MyAuthorizations;
