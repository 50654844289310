import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  ReactNode,
  KeyboardEvent,
  JSX,
  useState,
} from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formHelper } from "@utils/helpers/form-helper";
import { basicMandatoryRule } from "@components/core/inputs/InputFormRules";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import Password from "antd/es/input/Password";

export interface InputPasswordFormFieldProps {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  showTooltip?: boolean;
  labelTooltip?: ReactNode;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
  addonAfter?: string | JSX.Element;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: { [key in string]: string } | string;
  disabled?: boolean;
}

const PasswordFormField: FunctionComponent<InputPasswordFormFieldProps> = (
  props: InputPasswordFormFieldProps,
) => {
  const {
    showLabel,
    showTooltip,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName = "",
    fieldClassName = "",
    onChange,
    onPressEnter,
    maxLength,
    addonAfter,
    addonBefore,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onInput,
    initialValue,
    disabled,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const inputLabel = t(`${module}.fields.${i18nField}.label`);
  const inputPlaceholder = placeholder
    ? placeholder
    : t(`${module}.fields.${i18nField}.placeholder`);

  const sl = showLabel ?? readOnly;

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <Form.Item
      name={
        typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
      }
      key={key}
      label={
        sl ? (
          <FormFieldLabel
            field={field}
            module={module}
            label={inputLabel}
            className={labelClassName}
            showTooltip={showTooltip}
            tooltipLabel={labelTooltip}
          />
        ) : (
          ""
        )
      }
      rules={[
        basicMandatoryRule({
          field,
          emptyErrorMessage,
          constraints: { isRequired, isBlankAllowed: true },
        }),
        ...rules,
      ]}
      className={className}
      initialValue={initialValue}
    >
      <Password
        disabled={readOnly ?? disabled}
        className={`${readOnly ? "readonly-form-field" : ""} ${fieldClassName}`}
        placeholder={inputPlaceholder}
        id={key}
        onChange={onChange}
        onPressEnter={onPressEnter}
        maxLength={maxLength}
        addonAfter={addonAfter ? addonAfter : ""}
        addonBefore={addonBefore ? addonBefore : ""}
        onInput={onInput}
        data-testid="input-form-field"
        data-test-type="input-text"
        data-test={field}
        data-alt={inputPlaceholder}
        visibilityToggle={{
          visible: passwordVisible,
          onVisibleChange: setPasswordVisible,
        }}
      />
    </Form.Item>
  );
};

export default PasswordFormField;
