import { createEffect } from "effector";
import { restCreationMultipartHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import {
  CheckDocumentAuthenticationDetailsResponseDto,
  checkDocumentAuthenticationDetailsResponseDtoDecoder,
  checkDocumentAuthenticationDetailsResponseDtoDecoderContent,
} from "@state/documents/dto/response/transaction.details.response.dto";

const documentsBaseUrl = `${configuration.backendBaseUrl}/documents`;

export const requestCheckDocumentAuthentication = createEffect({
  handler: restCreationMultipartHandler<
    CheckDocumentAuthenticationDetailsResponseDto,
    typeof checkDocumentAuthenticationDetailsResponseDtoDecoderContent,
    typeof checkDocumentAuthenticationDetailsResponseDtoDecoder
  >(
    `${documentsBaseUrl}/check-authenticity`,
    undefined,
    checkDocumentAuthenticationDetailsResponseDtoDecoder,
  ),
});

export const requestPublicCheckDocumentAuthentication = createEffect({
  handler: restCreationMultipartHandler<
    CheckDocumentAuthenticationDetailsResponseDto,
    typeof checkDocumentAuthenticationDetailsResponseDtoDecoderContent,
    typeof checkDocumentAuthenticationDetailsResponseDtoDecoder
  >(
    `${documentsBaseUrl}/check-authenticity/public`,
    undefined,
    checkDocumentAuthenticationDetailsResponseDtoDecoder,
  ),
});
