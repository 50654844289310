export enum CheckType {
  ORDINAL_NUMBER = "ORDINAL_NUMBER",
  ID_CARD = "ID_CARD",
  ADDRESS_EMAIL = "ADDRESS_EMAIL",
  DEVICE = "DEVICE",
  PIN_CODE = "PIN_CODE",
}

export enum CheckStatus {
  OK = "OK",
  KO = "KO",
}
