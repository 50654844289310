import { FunctionComponent, JSX, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload, UploadProps } from "antd";
import {
  ImportFileCardStatus,
  ImportFileCardStatusProperties,
  importFileCardStatusProperties,
} from "@utils/enums/flipcard.enum";
import FlipCard from "@components/core/cards/FlipCard";

const { Dragger } = Upload;

interface ImportFilesCardProps {
  name: string;
  icon: JSX.Element;
  module: string;
  multiple?: boolean;
  accept?: string;
  className?: string;
  props?: UploadProps;
  cardState?: ImportFileCardStatus;
  onFlip: (flipped: boolean) => void;
}

const ImportFilesCard: FunctionComponent<ImportFilesCardProps> = ({
  name,
  icon,
  module,
  props,
  multiple = false,
  className = "",
  cardState = ImportFileCardStatus.DEFAULT,
  onFlip,
}) => {
  const { t } = useTranslation();

  const [backSide, setBackSide] = useState<ImportFileCardStatusProperties>();

  const title = t(`${module}.DEFAULT.title`);
  const subtitle = t(`${module}.DEFAULT.subtitle`, "");

  useEffect(() => {
    if (cardState !== ImportFileCardStatus.DEFAULT) {
      setBackSide(importFileCardStatusProperties[cardState]);
    }
  }, [cardState]);

  const renderFrontSide = () => {
    return (
      <div className="p-24">
        <div className="card-import-files-icon">{icon}</div>
        <div className="card-import-files-title-container">
          <p className="card-import-files-title">{title}</p>
          {subtitle && <p className="card-import-files-subtitle">{subtitle}</p>}
        </div>
        <p className="card-import-files-description">
          <span>{t("dashboard.header.importFiles.dragPdf")}</span>
          <span>{t("dashboard.header.importFiles.or")}</span>
          <span>{t("dashboard.header.importFiles.importPdf")}</span>
        </p>
      </div>
    );
  };

  const renderBackSide = () => {
    return (
      <div className="p-24 text-center">
        {backSide?.icon && (
          <div className="card-import-files-icon mb-24">{backSide.icon}</div>
        )}
        <h4 className="text-white mb-0">
          {t(`${module}.${backSide?.key}.title`)}
        </h4>
      </div>
    );
  };

  const renderOtherFrontSide = () => {
    return (
      <div className="p-24">
        <div className="card-import-files-icon mb-24">{icon}</div>
        <div className="card-import-files-title-container">
          <p className="card-import-files-title">
            {t(`${module}.UPLOADED.title`)}
          </p>
        </div>
        <p className="card-import-files-description">
          <span>{"Cliquer pour annuler"}</span>
        </p>
      </div>
    );
  };

  return (
    <Dragger
      className={`app-card-import-files ${className}`}
      name={`import-files-${name}`}
      multiple={multiple}
      showUploadList={false}
      disabled={cardState !== ImportFileCardStatus.DEFAULT}
      onChange={() => {
        onFlip(false);
      }}
      {...props}
    >
      <FlipCard
        frontSide={
          cardState === ImportFileCardStatus.DEFAULT
            ? renderFrontSide()
            : renderOtherFrontSide()
        }
        frontSideVariant="light-transparent"
        backSide={renderBackSide()}
        cardClassName="app-card-import-files-container"
        backSideVariant={importFileCardStatusProperties[cardState].variant}
        flipped={importFileCardStatusProperties[cardState].flipped}
        onFlip={onFlip}
        flipOnClick={cardState !== ImportFileCardStatus.DEFAULT}
        autoFlipBack
      />
    </Dragger>
  );
};

export default ImportFilesCard;
