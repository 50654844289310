import { FunctionComponent } from "react";
import BasicLink from "@components/core/links/BasicLink";
import { useTranslation } from "react-i18next";
import configuration from "@utils/Config";

interface FooterProps {
  className?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const cguLink = configuration.cguLink;
  const cookiesLink = configuration.cookiesLink;

  return (
    <footer className={className}>
      <div className="footer-container content-contained content-xl">
        <div className="link-container">
          <BasicLink to={cookiesLink} title={t("footer.cookies")}>
            {t("footer.cookies")}
          </BasicLink>
          <BasicLink to={cguLink} title={t("footer.cgu")}>
            {t("footer.cgu")}
          </BasicLink>
        </div>
        <div className="copyright">{t("footer.copyright")}</div>
      </div>
    </footer>
  );
};

export default Footer;
