import { createEffect } from "effector";
import { restGetUniqueHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import { CaptchaSiteKeyResponseDto } from "@state/captcha/dto/response/captcha.site.key.response.dto";

const baseUrl = configuration.backendBaseUrl;
const captchaUrl = baseUrl + "/captcha";

export const requestGetCaptchaSiteKey = createEffect({
  handler: restGetUniqueHandler<CaptchaSiteKeyResponseDto>(captchaUrl),
});
