export enum ModuleEnum {
  AUTH = "01",
  USER = "02",
  RESOURCE = "03",
  ADMIN = "04",
  CONFIGURATION = "05",
  PREFERENCE = "06",
  ACCOUNT = "07",
  RECIPIENT = "08",
  VETERINARY_SOFTWARE = "09",
  DOCUMENT = "10",
  SIGNATURE = "11",
  ENROLLMENT = "12",
  DEVICE = "13",
  LICENSE = "14",
  AUTHORIZATION = "15",
}

export interface ModuleProperties {
  code: string;
}

export type ModulesProperties = {
  [key in string]: ModuleProperties;
};

export const modulesProperties: ModulesProperties = {
  [ModuleEnum.AUTH]: {
    code: "AUTH",
  },
  [ModuleEnum.USER]: {
    code: "USER",
  },
  [ModuleEnum.RESOURCE]: {
    code: "RESOURCE",
  },
  [ModuleEnum.ADMIN]: {
    code: "ADMIN",
  },
  [ModuleEnum.CONFIGURATION]: {
    code: "CONFIGURATION",
  },
  [ModuleEnum.PREFERENCE]: {
    code: "PREFERENCE",
  },
  [ModuleEnum.ACCOUNT]: {
    code: "ACCOUNT",
  },
  [ModuleEnum.RECIPIENT]: {
    code: "RECIPIENT",
  },
  [ModuleEnum.VETERINARY_SOFTWARE]: {
    code: "VETERINARY_SOFTWARE",
  },
  [ModuleEnum.DOCUMENT]: {
    code: "DOCUMENT",
  },
  [ModuleEnum.SIGNATURE]: {
    code: "SIGNATURE",
  },
  [ModuleEnum.ENROLLMENT]: {
    code: "ENROLLMENT",
  },
  [ModuleEnum.DEVICE]: {
    code: "DEVICE",
  },
  [ModuleEnum.LICENSE]: {
    code: "LICENSE",
  },
  [ModuleEnum.AUTHORIZATION]: {
    code: "AUTHORIZATION",
  },
};
