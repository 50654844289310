import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/core/layouts/PageLayout";
import { authenticationStore } from "@state/auth/AuthStore";
import MyAccountForm from "@views/core/admin/account/infos/form/MyAccountForm";
import MyPasswordForm from "@views/core/admin/account/infos/form/MyPasswordForm";
import MySmartphoneForm from "@views/core/admin/account/infos/form/MySmartphoneForm";

const MyAccountScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const currentUser = authenticationStore.getState()?.user;

  return (
    <PageLayout container title={t("users.edit.title")}>
      {currentUser ? (
        <>
          <MyAccountForm originAuthUser={currentUser} />
          <MyPasswordForm />
          <MySmartphoneForm />
        </>
      ) : (
        <></>
      )}
    </PageLayout>
  );
};

export default MyAccountScreen;
