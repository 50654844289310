import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { LicenseHeldResponseDto } from "@state/licenses/dto/response/license.held.response.dto";
import {
  requestAttributeLicense,
  requestGetHeldLicense,
  requestRejectLicense,
  requestRemoveLicense,
} from "@state/licenses/LicensesEffects";
import { useTranslation } from "react-i18next";
import BasicTable from "@components/core/tables/BasicTable";
import { columns as myLicenseColumns } from "@views/core/admin/licenses/columns/MyLicenseColumns";
import Section from "@components/core/section/Section";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";
import { UserProfile } from "@utils/enums/profile.enum";
import { licenseColorVariant } from "@views/core/admin/licenses/LicensesScreen";

const MyLicense: FunctionComponent = () => {
  const { t } = useTranslation();
  const spinIcon = <LoadingOutlined spin />;

  const authenticationContext = useUnit(authenticationStore);

  const searchHeldLicenseAllowed =
    authenticationContext?.user?.profiles.includes(UserProfile.VETERINARY);

  const [heldLicenseLoaded, setHeldLicenseLoaded] = useState<boolean>(true);
  const [heldLicenses, setHeldLicenses] = useState<LicenseHeldResponseDto[]>(
    [],
  );

  const fetchHeldLicense = useCallback(() => {
    if (searchHeldLicenseAllowed) {
      setHeldLicenseLoaded(false);
      void requestGetHeldLicense({}).finally(() => {
        setHeldLicenseLoaded(true);
      });
    }
  }, [searchHeldLicenseAllowed]);

  useEffect(() => {
    fetchHeldLicense();
  }, [fetchHeldLicense]);

  useEffect(() => {
    return requestGetHeldLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setHeldLicenses([result.data]);
      } else {
        setHeldLicenses([]);
      }
    });
  });

  useEffect(() => {
    return requestAttributeLicense.done.watch(({ result }) => {
      if (result.ok) {
        fetchHeldLicense();
      }
    });
  });

  useEffect(() => {
    return requestRejectLicense.done.watch(({ result }) => {
      if (result.ok) {
        fetchHeldLicense();
      }
    });
  });

  useEffect(() => {
    return requestRemoveLicense.done.watch(({ result }) => {
      if (result.ok) {
        fetchHeldLicense();
      }
    });
  });

  return (
    <Section title={t("licenses.myLicense.title")}>
      {!heldLicenseLoaded ? (
        <div className="text-center">
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <BasicTable
          dataSource={heldLicenses}
          columns={myLicenseColumns()}
          rowClassName={(record: LicenseHeldResponseDto) =>
            `row-${licenseColorVariant(record)}`
          }
        />
      )}
    </Section>
  );
};

export default MyLicense;
