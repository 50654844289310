import { FunctionComponent, useState } from "react";
import PageLayout from "@components/core/layouts/PageLayout";
import Section from "@components/core/section/Section";
import BasicButton from "@components/core/buttons/BasicButton";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import BasicTag from "@components/core/tags/BasicTag";
import BasicBadge from "@components/core/badges/BasicBadge";
import BasicDivider from "@components/core/divider/BasicDivider";
import BasicCard from "@components/core/cards/BasicCard";
import BasicAlert from "@components/core/alerts/BasicAlert";
import { Form, Tooltip } from "antd";
import InputFormField from "@components/core/inputs/InputFormField";
import { SelectFormField } from "@components/core/inputs/SelectFormField";
import { PROFILES_OPTIONS } from "@utils/enums/profile.enum";
import BasicModal from "@components/core/modals/BasicModal";
import DetailItem from "@components/core/items/DetailItem";
import { formValidateTriggers } from "@utils/Constant";
import SearchFormField from "@components/core/inputs/SearchFormField";
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
} from "@utils/helpers/toast-helper";
import DatePickerFormField from "@components/core/inputs/DatePickerFormField";
import CardSection from "@components/core/section/CardSection";
import Accordions from "@components/core/accordions/Accordions";
import logger from "@utils/logger";
import { useForm } from "antd/lib/form/Form";
import TextAreaFormField from "@components/core/inputs/TextAreaFormField";
import BasicProgress from "@components/core/progress/BasicProgress";
import FlipCard from "@components/core/cards/FlipCard";

const ComponentsScreen: FunctionComponent = () => {
  const [form] = useForm();
  const [isBasicModalVisible, setIsBasicModalVisible] =
    useState<boolean>(false);
  const [cardFlipped, setCardFlipped] = useState<boolean>(false);

  return (
    <PageLayout
      title="Composants"
      container
      contentInCard={false}
      returnRoute="#"
    >
      <>
        <Section title="Sections">
          <div>Section content (any content)</div>
        </Section>
        <CardSection title="Card sections">
          <div>Card section content (any content)</div>
        </CardSection>
        <CardSection title="Boutons">
          <>
            <div className="d-flex align-items-center flex-wrap">
              <BasicButton text="Primary" className="mr-16 mb-16" />
              <BasicButton disabled text="Primary" className="mr-16 mb-16" />
              <BasicButton
                variant="primary-outlined"
                text="Primary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="primary-outlined"
                text="Primary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="secondary"
                text="Secondary"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="secondary"
                text="Secondary"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="secondary-outlined"
                text="Secondary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="secondary-outlined"
                text="Secondary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="success"
                text="Success"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="success"
                text="Success"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="danger"
                text="Danger"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="danger"
                text="Danger"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="white"
                text="White"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="white"
                text="White"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="transparent"
                text="Transparent"
                className="mb-16"
              />
              <BasicButton
                disabled
                variant="transparent"
                text="Transparent"
                className="mb-16"
              />
            </div>
            <div className="d-flex align-items-center flex-wrap mt-16">
              <BasicButton text="Primary" size="sm" className="mr-16 mb-16" />
              <BasicButton
                disabled
                text="Primary"
                size="sm"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="primary-outlined"
                size="sm"
                text="Primary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="primary-outlined"
                size="sm"
                text="Primary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="secondary"
                size="sm"
                text="Secondary"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="secondary"
                size="sm"
                text="Secondary"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="secondary-outlined"
                size="sm"
                text="Secondary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="secondary-outlined"
                size="sm"
                text="Secondary outlined"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="success"
                size="sm"
                text="Success"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="success"
                size="sm"
                text="Success"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="danger"
                size="sm"
                text="Danger"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="danger"
                size="sm"
                text="Danger"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="white"
                size="sm"
                text="White"
                className="mr-16 mb-16"
              />
              <BasicButton
                disabled
                variant="white"
                size="sm"
                text="White"
                className="mr-16 mb-16"
              />
              <BasicButton
                variant="transparent"
                size="sm"
                text="Transparent"
                className="mb-16"
              />
              <BasicButton
                disabled
                variant="transparent"
                size="sm"
                text="Transparent"
                className="mb-16"
              />
            </div>
          </>
        </CardSection>
        <Section title="Badges">
          <div className="d-flex align-items-center flex-wrap">
            <BasicBadge number={1} className="mr-16 mb-16" />
            <BasicBadge variant="primary" number={12} className="mr-16 mb-16" />
            <BasicBadge
              variant="secondary"
              number={24}
              className="mr-16 mb-16"
            />
            <BasicBadge
              variant="tertiary"
              number={36}
              className="mr-16 mb-16"
            />
            <BasicBadge variant="success" number={48} className="mr-16 mb-16" />
            <BasicBadge variant="warning" number={60} className="mr-16 mb-16" />
            <BasicBadge variant="danger" number={72} className="mr-16 mb-16" />
            <BasicBadge variant="white" number={84} className="mr-16 mb-16" />
            <BasicBadge variant="grey" number={96} className="mr-16 mb-16" />
            <BasicBadge
              variant="dark-grey"
              number={99}
              className="mr-16 mb-16"
            />
            <BasicBadge variant="light-grey" number={99} className="mb-16" />
          </div>
        </Section>
        <Section title="Tags">
          <div className="d-flex align-items-center flex-wrap">
            <BasicTag label="Default" className="mr-16 mb-16" />
            <BasicTag
              variant="primary"
              label="Primary"
              className="mr-16 mb-16"
            />
            <BasicTag
              variant="secondary"
              label="Secondary"
              className="mr-16 mb-16"
            />
            <BasicTag
              variant="tertiary"
              label="Tertiary"
              className="mr-16 mb-16"
            />
            <BasicTag
              variant="success"
              label="Success"
              className="mr-16 mb-16"
            />
            <BasicTag
              variant="warning"
              label="Warning"
              className="mr-16 mb-16"
            />
            <BasicTag variant="danger" label="Danger" className="mr-16 mb-16" />
            <BasicTag variant="white" label="White" className="mr-16 mb-16" />
            <BasicTag variant="grey" label="Grey" className="mr-16 mb-16" />
            <BasicTag
              variant="dark-grey"
              label="Dark grey"
              className="mr-16 mb-16"
            />
            <BasicTag
              variant="light-grey"
              label="Light grey"
              className="mb-16"
            />
          </div>
        </Section>
        <Section title="Dividers">
          <div className="d-flex align-items-center flex-wrap">
            <BasicDivider className="mr-16 mb-16" />
            <BasicDivider variant="primary" className="mr-16 mb-16" />
            <BasicDivider variant="secondary" className="mr-16 mb-16" />
            <BasicDivider variant="tertiary" className="mr-16 mb-16" />
            <BasicDivider variant="success" className="mr-16 mb-16" />
            <BasicDivider variant="warning" className="mr-16 mb-16" />
            <BasicDivider variant="danger" className="mr-16 mb-16" />
            <BasicDivider variant="white" className="mr-16 mb-16" />
            <BasicDivider variant="grey" className="mr-16 mb-16" />
            <BasicDivider variant="dark-grey" className="mr-16 mb-16" />
            <BasicDivider variant="light-grey" className="mb-16" />
          </div>
        </Section>
        <Section title="Cards">
          <>
            <BasicCard className="mb-16">
              <>Basic card</>
            </BasicCard>
            <BasicCard
              onClick={() => {
                logger.info("clickable");
              }}
            >
              <>Basic clickable card</>
            </BasicCard>
          </>
        </Section>
        <Section title="Alerts">
          <>
            <BasicAlert text="Default alert" className="mb-16" />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Default alert with icon"
              className="mb-16"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Primary alert"
              variant="primary"
              className="mb-16"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Secondary alert"
              variant="secondary"
              className="mb-16"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Tertiary alert"
              variant="tertiary"
              className="mb-16"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Success alert"
              variant="success"
              className="mb-16"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Warning alert"
              variant="warning"
              className="mb-16"
            />
            <BasicAlert
              icon={<SearchOutlined />}
              text="Danger alert"
              variant="danger"
              className="mb-16"
            />
          </>
        </Section>
        <Section title="Toasts">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton
              variant="transparent"
              text="Info toast"
              onClick={() => {
                toastInfo("Info toast");
              }}
              className="mr-16 mb-16"
            />
            <BasicButton
              variant="success"
              text="Success toast"
              onClick={() => {
                toastSuccess("Success toast");
              }}
              className="mr-16 mb-16"
            />
            <BasicButton
              variant="warning"
              text="Warning toast"
              onClick={() => {
                toastWarning("Warning toast");
              }}
              className="mr-16 mb-16"
            />
            <BasicButton
              variant="danger"
              text="Error toast"
              onClick={() => {
                toastError("Error toast");
              }}
              className="mb-16"
            />
          </div>
        </Section>
        <Section title="Inputs">
          <Form {...formValidateTriggers} form={form} layout="vertical">
            <InputFormField
              form={form}
              module="components.form"
              field="firstName"
              required
            />
            <InputFormField
              form={form}
              module="components.form"
              field="lastName"
              required
              readOnly
            />
            <TextAreaFormField
              form={form}
              module="components.form"
              field="textarea"
            />
            <SelectFormField
              form={form}
              module="components.form"
              field="profile"
              required
              options={PROFILES_OPTIONS}
            />
            <SelectFormField
              form={form}
              module="components.form"
              field="profiles"
              required
              options={PROFILES_OPTIONS}
              mode="multiple"
            />
            <SearchFormField
              form={form}
              maxLength={100}
              module="components.form"
              field="email"
            />
            <DatePickerFormField
              form={form}
              module="components.form"
              field="date"
            />
          </Form>
        </Section>
        <Section title="Modals">
          <div className="d-flex align-items-center flex-wrap">
            <BasicButton
              text="Basic modal"
              onClick={() => {
                setIsBasicModalVisible(true);
              }}
              className="mr-16 mb-16"
            />
            <BasicModal
              title="Basic Modal"
              visible={isBasicModalVisible}
              onOk={() => {
                setIsBasicModalVisible(false);
              }}
              onCancel={() => {
                setIsBasicModalVisible(false);
              }}
              buttonText="Confirmer"
            >
              <div>Basic modal content</div>
            </BasicModal>
          </div>
        </Section>
        <CardSection title="Items">
          <>
            <DetailItem
              title="Detail item title"
              description="Detail item description"
            />
          </>
        </CardSection>
        <CardSection title="Tooltips">
          <>
            <Tooltip placement="top" title="Tooltip content">
              <span className="text-secondary">
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          </>
        </CardSection>
        <Section title="Accordions">
          <>
            <Accordions
              sections={[
                {
                  key: "section_1",
                  title: "Section 1",
                  content:
                    "<h2>Titre de contenu de section</h2><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
                    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " +
                    "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>",
                },
                {
                  key: "section_2",
                  title: "Section 2",
                  content: (
                    <div>
                      <h2>Titre de contenu de section</h2>
                      <span>
                        Lorem ipsum dolor sit amet,consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </span>
                    </div>
                  ),
                },
              ]}
            />
          </>
        </Section>
        <Section title="Accordions (with collapse, expandIconLeft)">
          <>
            <Accordions
              collapseOthers
              expandIconLeft
              sections={[
                {
                  key: "0",
                  title: "Section 1",
                  content: (
                    <div>
                      <h2>Titre de contenu de section</h2>
                      <span>
                        Lorem ipsum dolor sit amet,consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </span>
                    </div>
                  ),
                },
                {
                  key: "1",
                  title: "Section 2",
                  content: (
                    <div>
                      <h2>Titre de contenu de section</h2>
                      <span>
                        Lorem ipsum dolor sit amet,consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </span>
                    </div>
                  ),
                },
              ]}
            />
          </>
        </Section>
        <Section title="Progress">
          <>
            <BasicProgress percent={0} className="mb-16" />
            <BasicProgress percent={50} className="mb-16" />
            <BasicProgress percent={100} className="mb-16" />
            <BasicProgress disabled className="mb-16" />
          </>
        </Section>
        <Section title="FlipCards">
          <>
            <FlipCard
              frontSide={
                <div
                  onClick={() => {
                    setCardFlipped(true);
                  }}
                >
                  Avant de la carte
                </div>
              }
              backSide={<div>Arrière de la carte</div>}
              frontSideVariant={"default"}
              backSideVariant={"success"}
              flipped={cardFlipped}
              onFlip={setCardFlipped}
            />
          </>
        </Section>
      </>
    </PageLayout>
  );
};

export default ComponentsScreen;
