import { Button, Form, Modal } from "antd";
import Otp from "@components/core/modals/Otp";
import { FunctionComponent, useState } from "react";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { requestGetUserInfo, requestOtp } from "@state/auth/AuthEffects";
import { setUser } from "@state/auth/AuthEvents";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formValidateTriggers } from "@utils/Constant";
import { toastError } from "@utils/helpers/toast-helper";
import { computeRedirectUrlFrom } from "@utils/helpers/query-helper";
import { AuthResultResponseDto } from "@state/auth/dto/response/auth.result.response.dto";
import { apiHelper } from "@utils/helpers/api.helper";
import { ApiErrors } from "@utils/ApiErrors";

function getOtpTyped(field: string, size: number): string {
  return [...Array<string>(size)]
    .map(
      (e, i) =>
        document
          .getElementById(`${field}-${i.toString()}`)
          ?.getAttribute("value") ?? "",
    )
    .join("");
}

interface Props {
  userEmail: string;
  visible: boolean;
  onCancel?: () => void;
}

const FIELD_NAME = "otpKey";

const OtpMailModal: FunctionComponent<Props> = (props: Props) => {
  const { onCancel, visible, userEmail } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleOk = (complete?: string): void => {
    const value = `${getOtpTyped(FIELD_NAME, complete ? 5 : 6)}${
      complete ?? ""
    }`;
    handleSubmitOtp(value);
    setTimeout(() => {
      form.resetFields();
    }, 1000);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleAuthentication = () => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
        const redirecturl = computeRedirectUrlFrom(location.search);
        navigate(redirecturl);
      }
    });
  };

  const handleSubmitOtp = (value: string): void => {
    setIsLoading(true);
    requestOtp({
      dto: {
        email: userEmail,
        otp: value,
      },
    })
      .then((authResponse: ParsedResponse<AuthResultResponseDto>) => {
        if (authResponse.responseCode === 200 && authResponse.data) {
          handleAuthentication();
        } else if (authResponse.errorCode) {
          if (
            authResponse.errorCode === ApiErrors.SSO_UNAUTHORIZED.valueOf() &&
            authResponse.maxAttempts &&
            authResponse.attempts
          ) {
            apiHelper.showErrorFromApiErrorCode(authResponse.errorCode, {
              remainingAccessAttempts:
                authResponse.maxAttempts - authResponse.attempts,
            });
          } else {
            apiHelper.showErrorFromApiErrorCode(authResponse.errorCode);
          }
        } else {
          toastError(t("login.messages.error"));
        }
      })
      .catch(() => {
        toastError(t("login.messages.error"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title={t("otp.mail.title")}
      open={visible}
      confirmLoading={isLoading}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            handleCancel();
          }}
        >
          {t("otp.mail.buttons.cancel")}
        </Button>,
        <Button
          key="send"
          onClick={() => {
            form.submit();
          }}
          className="btn-primary"
        >
          {t("otp.mail.buttons.send")}
        </Button>,
      ]}
    >
      <p>{t("otp.mail.content")}</p>
      <Form form={form} onFinish={handleOk} {...formValidateTriggers}>
        <Otp field={FIELD_NAME} size={6} onComplete={handleOk} />
      </Form>
    </Modal>
  );
};

export default OtpMailModal;
