import { FunctionComponent, JSX, useState } from "react";
import { Collapse } from "antd";
import parse from "html-react-parser";

export interface AccordionSection {
  key: string;
  title: string;
  content: string | JSX.Element;
}

export interface AccordionsProps {
  sections?: AccordionSection[];
  children?: JSX.Element;
  collapseOthers?: boolean;
  defaultActiveKey?: number;
  expandIconLeft?: boolean;
  hideOnSelect?: boolean;
  className?: string;
}

const Accordions: FunctionComponent<AccordionsProps> = ({
  sections = [],
  children,
  collapseOthers = false,
  defaultActiveKey = 0,
  expandIconLeft = false,
  hideOnSelect = false,
  className = "",
}) => {
  const [expandedIndex, setExpandedIndex] = useState<string | undefined>(
    undefined,
  );

  const handleChange = (index: string | string[]) => {
    if (collapseOthers) {
      if (index) setExpandedIndex(index[0]);
      else setExpandedIndex(undefined);
    }
  };

  return (
    <div data-testid="accordions">
      <Collapse
        onChange={handleChange}
        accordion={collapseOthers}
        className={`app-accordions ${className}`}
        defaultActiveKey={defaultActiveKey || 0}
        expandIconPosition={expandIconLeft ? "start" : "end"}
        items={
          children
            ? [
                {
                  key: "1",
                  children: children,
                },
              ]
            : sections.map((section: AccordionSection, index: number) => ({
                "key": section.key,
                "label": (
                  <span data-test="accordions-section-header">
                    {section.title}
                  </span>
                ),
                "children": (
                  <div data-test="accordions-section-content">
                    {typeof section.content === "string"
                      ? parse(section.content)
                      : section.content}
                  </div>
                ),
                "className":
                  hideOnSelect &&
                  collapseOthers &&
                  expandedIndex &&
                  expandedIndex !== index.toString()
                    ? "d-none"
                    : "",
                /* eslint-disable @typescript-eslint/naming-convention */
                "data-test": "accordions-panel",
                /* eslint-enable @typescript-eslint/naming-convention */
              }))
        }
      />
    </div>
  );
};

export default Accordions;
