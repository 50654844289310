import configuration from "@utils/Config";
import { createEffect } from "effector";
import { restListHandler } from "@utils/rest/RestClient";
import { CheckResponseDto } from "@state/enrollment/dto/response/check.response.dto";

const baseUrl = configuration.backendBaseUrl;
const enrollmentUrl = baseUrl + "/enrollment/";

export const requestGetEnrollment = createEffect({
  handler: restListHandler<CheckResponseDto>(enrollmentUrl),
});
