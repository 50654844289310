import i18n, { t } from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import DateFormat from "@utils/DateFormat";
import BasicButton from "@components/core/buttons/BasicButton";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  AutorisationStatus,
  autorisationStatusProperties,
} from "@utils/enums/autorisations.status.enum";
import { showConfirm } from "@components/core/modals/ConfirmModal";
import {
  requestBlockVeterinarySoftware,
  requestAuthorizeVeterinarySoftware,
} from "@state/authorization/AuthorizationEffects";
import { AuthorizationDetailResponseDto } from "@state/authorization/response/authorization.detail.response.dto";

export const columns = (): ColumnsType<AuthorizationDetailResponseDto> => [
  {
    title: i18n.t("authorizations.myAutorisations.table.submittedBy"),
    dataIndex: "submittedBy",
  },
  {
    title: i18n.t("authorizations.myAutorisations.table.lastTransactionDate"),
    dataIndex: "lastTransactionDate",
    render: (lastDate: string) => {
      return DateFormat.dashboardDate(new Date(lastDate));
    },
  },
  {
    title: i18n.t("authorizations.myAutorisations.table.status"),
    dataIndex: "status",
    render: (
      status: AutorisationStatus,
      record: AuthorizationDetailResponseDto,
    ) => (
      <span
        className={`text-${i18n.t(autorisationStatusProperties[status].variant)}`}
      >
        {`${i18n.t(autorisationStatusProperties[status].label)}${record.statusDate ? ` ${i18n.t("authorizations.myAutorisations.until")} ${DateFormat.dashboardDate(new Date(record.statusDate))}` : ""}`}
      </span>
    ),
  },
  {
    title: "",
    dataIndex: "status",
    width: 188,
    render: (
      status: AutorisationStatus,
      record: AuthorizationDetailResponseDto,
    ) => (
      <div className="d-flex align-items-center justify-content-end">
        {status === AutorisationStatus.AUTHORIZED ? (
          <BasicButton
            size="sm"
            variant="transparent"
            icon={<CloseOutlined />}
            iconRight
            text={i18n.t("buttons.block")}
            onClick={() => {
              void showConfirm({
                title: t("authorizations.edit.modals.block.title"),
                content: t("authorizations.edit.modals.block.content", {
                  solution: record.submittedBy,
                }),
              }).then((confirmed: boolean) => {
                if (confirmed) {
                  void requestBlockVeterinarySoftware({
                    upperEntityId: record.id,
                  });
                }
              });
            }}
          />
        ) : (
          <BasicButton
            size="sm"
            variant="transparent"
            icon={<CheckOutlined />}
            iconRight
            text={i18n.t("buttons.authorize")}
            onClick={() => {
              void showConfirm({
                title: t("authorizations.edit.modals.authorize.title"),
                content: t("authorizations.edit.modals.authorize.content", {
                  solution: record.submittedBy,
                }),
              }).then((confirmed: boolean) => {
                if (confirmed) {
                  void requestAuthorizeVeterinarySoftware({
                    upperEntityId: record.id,
                  });
                }
              });
            }}
          />
        )}
      </div>
    ),
  },
];
