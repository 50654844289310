import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, Form } from "antd";
import configuration from "@utils/Config";

interface PropsType {
  className?: string;
}

const CguFormField: React.FC<PropsType> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const key = "cguAccepted";

  const cguHref = configuration.cguLink;

  return (
    <Form.Item
      name="cguAccepted"
      key={key}
      initialValue={false}
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject(
                  new Error(t("registration.form.fields.cguUnchecked")),
                ),
        },
      ]}
    >
      <Checkbox id={key} className={className}>
        <span>
          <Trans
            i18nKey="registration.form.fields.cguAccepted"
            components={{
              CguLink: (
                <a rel="noreferrer noopener" target="_blank" href={cguHref}>
                  Link
                </a>
              ),
            }}
          />
        </span>
      </Checkbox>
    </Form.Item>
  );
};

export default CguFormField;
