import { FunctionComponent, JSX } from "react";
import { nanoid } from "nanoid";

interface DetailItemProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  className?: string;
}

const DetailItem: FunctionComponent<DetailItemProps> = ({
  title,
  description,
  className = "",
}) => {
  const dataId = nanoid();

  return (
    <div
      className={`app-item-detail ${className}`}
      data-test="details-label"
      data-test-for={dataId}
      data-alt={title}
    >
      <h3 className="item-title">{title}</h3>
      <p
        className="item-description overflow-ellipsis"
        data-test-type="details-value"
        data-test={dataId}
      >
        {description}
      </p>
    </div>
  );
};

export default DetailItem;
