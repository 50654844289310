import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/core/layouts/PageLayout";
import BasicCard from "@components/core/cards/BasicCard";
import MyAuthorizations from "@views/core/admin/licenses/MyAuthorizations";
import MyLicense from "@views/core/admin/licenses/MyLicense";
import LicencesManagement from "@views/core/admin/licenses/LicencesManagement";
import { LicenseManagedResponseDto } from "@state/licenses/dto/response/license.managed.response.dto";
import { LicenseHeldResponseDto } from "@state/licenses/dto/response/license.held.response.dto";

export const licenseColorVariant = (
  license: LicenseManagedResponseDto | LicenseHeldResponseDto,
) => {
  if (license.status === "EXPIRED") {
    return "danger";
  } else if (license.status === "ASSIGNED") {
    return "success";
  }
  return "white";
};

const LicensesScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout container title={t("licenses.title")}>
      <>
        <MyLicense />
        <MyAuthorizations />
        <LicencesManagement />
        <BasicCard className="mt-48">
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-16">
            <p className="flex-grow-1 m-0 text-transparent-card">
              <br />
              <br />
            </p>
          </div>
        </BasicCard>
      </>
    </PageLayout>
  );
};

export default LicensesScreen;
