import i18n, { t } from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import DateFormat from "@utils/DateFormat";
import BasicButton from "@components/core/buttons/BasicButton";
import { CloseOutlined } from "@ant-design/icons";
import {
  differenceInYearsMonthsDays,
  elapsedDaysInPercent,
} from "@utils/helpers/date-helper";
import BasicProgress from "@components/core/progress/BasicProgress";
import { requestRejectLicense } from "@state/licenses/LicensesEffects";
import { showConfirm } from "@components/core/modals/ConfirmModal";
import { LicenseHeldResponseDto } from "@state/licenses/dto/response/license.held.response.dto";

export const columns = (): ColumnsType<LicenseHeldResponseDto> => [
  {
    title: i18n.t("licenses.myLicense.table.period"),
    dataIndex: "activationDate",
    className: "change-color-with-status",
    render: (activationDate: string, record: LicenseHeldResponseDto) => {
      return activationDate && record.expirationDate
        ? `${DateFormat.dashboardDate(new Date(activationDate))}${record.expirationDate ? ` ${i18n.t("licenses.myLicense.to")} ${DateFormat.dashboardDate(new Date(record.expirationDate))}` : ""}`
        : i18n.t("licenses.waitingAssignment");
    },
  },
  {
    title: i18n.t("licenses.myLicense.table.remainingDuration"),
    dataIndex: "expirationDate",
    className: "change-color-with-status",
    width: 202,
    showSorterTooltip: false,
    render: (expirationDate: string, record: LicenseHeldResponseDto) => {
      return record.activationDate && expirationDate ? (
        <div className="d-flex flex-column gap-10">
          <p className="m-0 d-flex align-items-center justify-content-between gap-8">
            <span>
              {differenceInYearsMonthsDays(
                new Date(),
                new Date(expirationDate),
              )}
            </span>
          </p>
          <BasicProgress
            className="inverted-progress"
            percent={elapsedDaysInPercent(
              record.activationDate,
              expirationDate,
            )}
          />
        </div>
      ) : (
        <div className="d-flex flex-column gap-10">
          <span>
            {i18n.t(`common.${record.durationUnit}`, {
              count: record.durationValue,
            })}
          </span>
          <BasicProgress percent={100} disabled />
        </div>
      );
    },
  },
  {
    title: i18n.t("licenses.myLicense.table.assignBy"),
    dataIndex: "managerName",
    showSorterTooltip: false,
  },
  {
    title: "",
    width: 188,
    render: () => (
      <div className="d-flex align-items-center justify-content-end">
        <BasicButton
          size="sm"
          variant="transparent"
          icon={<CloseOutlined />}
          iconRight
          text={i18n.t("buttons.reject")}
          onClick={() => {
            void showConfirm({
              title: t("licenses.actions.reject.title"),
              content: t("licenses.actions.reject.content"),
            }).then((confirmed: boolean) => {
              if (confirmed) {
                void requestRejectLicense({});
              }
            });
          }}
        />
      </div>
    ),
  },
];
