import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { LicenseManagedResponseDto } from "@state/licenses/dto/response/license.managed.response.dto";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import BasicTable from "@components/core/tables/BasicTable";
import { Form, TablePaginationConfig } from "antd";
import BasicButton from "@components/core/buttons/BasicButton";
import { PlusOutlined } from "@ant-design/icons";
import { formValidateTriggers } from "@utils/Constant";
import {
  columns as licensesManagementColumns,
  computeOrdinalNumberField,
} from "@views/core/admin/licenses/columns/LicensesManagementColumns";
import InputFormField from "@components/core/inputs/InputFormField";
import Section from "@components/core/section/Section";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "@utils/helpers/toast-helper";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { RcFile } from "antd/lib/upload";
import {
  requestAttributeLicense,
  requestGetManagedLicenses,
  requestImportLicenseByFile,
  requestImportLicenseByKey,
  requestRejectLicense,
  requestRemoveLicense,
} from "@state/licenses/LicensesEffects";
import { useForm } from "antd/lib/form/Form";
import { formHelper } from "@utils/helpers/form-helper";
import { licenseColorVariant } from "@views/core/admin/licenses/LicensesScreen";

const LicencesManagement: FunctionComponent = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [licenseNumber, setLicenseNumber] = useState<string>("");

  const [managedLicencesLoaded, setManagedLicencesLoaded] =
    useState<boolean>(true);

  const [managedLicenses, setManagedLicenses] = useState<
    LicenseManagedResponseDto[]
  >([]);

  const [sorter, setSorter] = useState<
    | SorterResult<LicenseManagedResponseDto>
    | SorterResult<LicenseManagedResponseDto>[]
  >();

  const handleManagedLicensesTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<LicenseManagedResponseDto>
      | SorterResult<LicenseManagedResponseDto>[],
  ) => {
    if ((sorter as SorterResult<LicenseManagedResponseDto>).column) {
      setSorter(sorter);
    } else {
      setSorter({});
    }
  };

  const fetchManagedLicenses = useCallback(() => {
    setManagedLicencesLoaded(false);
    void requestGetManagedLicenses({
      sorter,
    }).finally(() => {
      setManagedLicencesLoaded(true);
    });
  }, [sorter]);

  useEffect(() => {
    fetchManagedLicenses();
  }, [fetchManagedLicenses]);

  useEffect(() => {
    return requestGetManagedLicenses.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setManagedLicenses(result.data);
      }
    });
  });

  const importButtonClickHandler = () => {
    const input = document.getElementById("fileInput");
    if (input != null) {
      input.click();
    }
  };

  const handleAttributeLicense = (licenseId: string) => {
    const ordinalNumber = form.getFieldValue(
      `ordinalNumber ${licenseId}`,
    ) as string;
    const ordinalNumberRegex = /^[a-zA-Z0-9]{1,20}$/;
    if (!RegExp(ordinalNumberRegex).exec(ordinalNumber)) {
      toastError(i18n.t("licenses.errors.assign.invalidOrdinalNumber"));
    } else {
      attributeLicense(licenseId, ordinalNumber);
    }
  };

  const attributeLicense = useCallback(
    (licenseId: string, ordinalNumber: string) => {
      void requestAttributeLicense({
        upperEntityId: licenseId + "/assign/" + ordinalNumber,
      });
    },
    [],
  );

  useEffect(() => {
    return requestAttributeLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const modifiedLicenses = managedLicenses.map((license) => {
          if (license.key === result.data?.key) {
            return result.data;
          }
          return license;
        });

        setManagedLicenses(modifiedLicenses);
        form.setFieldValue(computeOrdinalNumberField(result.data.id), "");
      } else if (!result.ok) {
        formHelper.handleFormErrors("licenses.form.fields", form, result);
      }
    });
  });

  const removeLicense = useCallback(
    (licenseId: string, ordinalNumber: string) => {
      void requestRemoveLicense({
        upperEntityId: licenseId + "/remove/" + ordinalNumber,
      });
    },
    [],
  );

  useEffect(() => {
    return requestRemoveLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const modifiedLicenses = managedLicenses.map((license) => {
          if (license.key === result.data?.key) {
            return result.data;
          }
          return license;
        });

        setManagedLicenses(modifiedLicenses);
      } else if (!result.ok) {
        toastError(
          t("licenses.errors.remove", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  const handleRemoveLicense = (licenseId: string, ordinalNumber: string) => {
    removeLicense(licenseId, ordinalNumber);
  };

  useEffect(() => {
    return requestRejectLicense.done.watch(() => {
      fetchManagedLicenses();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const importLicenseByFile = useCallback((formData: FormData) => {
    void requestImportLicenseByFile({
      dto: formData,
    });
  }, []);

  useEffect(() => {
    return requestImportLicenseByFile.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setManagedLicenses(result.data);
        if (result.responseCode === 200) {
          toastSuccess(t(`licenses.success.import-by-file`));
        } else {
          toastWarning(t(`licenses.warning.import-by-file`));
        }
      } else if (!result.ok) {
        toastError(
          t("licenses.errors.import.file", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  const handleFileSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files != null && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContentArray = new Uint8Array(
          event.target?.result as ArrayBuffer,
        );

        const fileBlob = new Blob([fileContentArray], {
          type: file.type,
        });

        const formData = new FormData();
        formData.append("file-data", fileBlob, file.name);
        importLicenseByFile(formData);
      };
      reader.readAsArrayBuffer(file as RcFile);
    }
  };

  const handleSubmit = () => {
    if (form.getFieldValue("licenseNumber") != "") {
      importLicenseByKey();
    } else {
      toastError(t("licenses.errors.import.emptyKey"));
    }
  };

  const importLicenseByKey = useCallback(() => {
    const key = form.getFieldValue("licenseNumber") as string;
    void requestImportLicenseByKey({
      dto: {
        key: key,
      },
    });
  }, [form]);

  useEffect(() => {
    return requestImportLicenseByKey.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setManagedLicenses(result.data);
        form.setFieldValue("licenseNumber", "");
        toastSuccess(t("licenses.success.import"));
      } else if (!result.ok) {
        toastError(
          t("licenses.errors.import.key", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  return (
    <Section
      title={t("licenses.licensesManagement.title")}
      className="mb-48"
      rightContent={
        <>
          <BasicButton
            size="sm"
            variant="primary-outlined"
            icon={<PlusOutlined />}
            iconRight
            onClick={() => {
              importButtonClickHandler();
            }}
            text={t("licenses.licensesManagement.import")}
          />
          <input
            id="fileInput"
            type="file"
            name="fileInput"
            hidden
            onChange={(e) => {
              handleFileSubmit(e);
            }}
            accept=".csv,.xlsx,.xls"
          />
        </>
      }
    >
      <Form
        {...formValidateTriggers}
        form={form}
        layout="vertical"
        className="table-licenses-management"
      >
        <>
          <BasicTable
            dataSource={managedLicenses}
            columns={licensesManagementColumns(
              form,
              handleAttributeLicense,
              handleRemoveLicense,
            )}
            rowClassName={(record: LicenseManagedResponseDto) =>
              `row-${licenseColorVariant(record)}`
            }
            isLoading={!managedLicencesLoaded}
            onChange={handleManagedLicensesTableChange}
          />
          <div className="table-licenses-management-add-row">
            <InputFormField
              form={form}
              module="licenses.form"
              field="licenseNumber"
              onChange={() => {
                setLicenseNumber(form.getFieldValue("licenseNumber") as string);
              }}
              className="mb-0"
            />
            <BasicButton
              size="sm"
              variant="primary-outlined"
              disabled={licenseNumber === ""}
              onClick={() => {
                handleSubmit();
              }}
              icon={<PlusOutlined />}
            />
          </div>
        </>
      </Form>
    </Section>
  );
};

export default LicencesManagement;
