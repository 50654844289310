import { FunctionComponent, JSX } from "react";
import { Modal } from "antd";
import BasicButton from "@components/core/buttons/BasicButton";
import i18n from "i18next";

interface BasicModalProps {
  title?: string | JSX.Element;
  visible: boolean;
  closable?: boolean;
  onOk?: () => void;
  onCancel: () => void;
  buttonText?: string;
  children: string | JSX.Element;
  className?: string;
  footer?: JSX.Element | null;
}

const BasicModal: FunctionComponent<BasicModalProps> = ({
  title,
  visible,
  closable = true,
  onOk,
  onCancel,
  buttonText,
  children,
  className = "",
  footer = (
    <div className="d-flex justify-content-end">
      <BasicButton
        variant="primary-outlined"
        text={i18n.t("buttons.cancel")}
        onClick={onCancel}
      />
      {onOk && (
        <BasicButton variant="primary" text={buttonText} onClick={onOk} />
      )}
    </div>
  ),
}) => {
  return (
    <Modal
      centered
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      className={`app-modal ${className}`}
      closable={closable}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;
