declare global {
  interface Window {
    VITE_REACT_APP_BACKEND_BASE_URL?: string;
    VITE_REACT_APP_ADMIN_BASE_URL?: string;
    VITE_REACT_APP_FRONTEND_BASE_URL?: string;
    VITE_REACT_APP_ENVIRONMENT?: string;
    VITE_REACT_APP_COOKIE_BANNER_ACTIVE?: boolean;
    VITE_REACT_APP_INFOS_ACTIVE?: boolean;
    VITE_REACT_APP_X_API_KEY?: string;
    VITE_REACT_APP_CGU_LINK?: string;
    VITE_REACT_APP_COOKIES_LINK?: string;
  }
}

interface ConfigInterface {
  backendBaseUrl: string;
  adminBaseUrl: string;
  frontendBaseUrl: string;
  environment: string;
  cookieBannerActive: boolean;
  appInfosActive: boolean;
  xApiKey: string;
  cguLink: string;
  cookiesLink: string;
}

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
const configuration: ConfigInterface = {
  backendBaseUrl:
    (import.meta.env.VITE_REACT_APP_BACKEND_BASE_URL as string) ??
    window.VITE_REACT_APP_BACKEND_BASE_URL ??
    "MISSING_REACT_APP_BACKEND_BASE_URL",
  adminBaseUrl:
    (import.meta.env.VITE_REACT_APP_ADMIN_BASE_URL as string) ??
    window.VITE_REACT_APP_ADMIN_BASE_URL ??
    "MISSING_REACT_APP_ADMIN_BASE_URL",
  frontendBaseUrl:
    (import.meta.env.VITE_REACT_APP_FRONTEND_BASE_URL as string) ??
    window.VITE_REACT_APP_FRONTEND_BASE_URL ??
    "MISSING_REACT_APP_FRONTEND_BASE_URL",
  environment:
    (import.meta.env.VITE_REACT_APP_ENVIRONMENT as string) ??
    window.VITE_REACT_APP_ENVIRONMENT ??
    "MISSING_REACT_APP_ENVIRONMENT",
  cookieBannerActive:
    import.meta.env.VITE_REACT_APP_COOKIE_BANNER_ACTIVE === "true"
      ? true
      : window.VITE_REACT_APP_COOKIE_BANNER_ACTIVE ?? false,
  appInfosActive:
    import.meta.env.VITE_REACT_APP_INFOS_ACTIVE === "true"
      ? true
      : window.VITE_REACT_APP_INFOS_ACTIVE ?? false,
  xApiKey:
    (import.meta.env.VITE_REACT_APP_X_API_KEY as string) ??
    window.VITE_REACT_APP_X_API_KEY ??
    "MISSING_REACT_APP_X_API_KEY",
  cguLink:
    (import.meta.env.VITE_REACT_APP_CGU_LINK as string) ??
    window.VITE_REACT_APP_CGU_LINK ??
    "MISSING_REACT_APP_CGU_LINK",
  cookiesLink:
    (import.meta.env.VITE_REACT_APP_COOKIES_LINK as string) ??
    window.VITE_REACT_APP_COOKIES_LINK ??
    "MISSING_REACT_APP_COOKIES_LINK",
};
/* eslint-enable @typescript-eslint/no-unnecessary-condition */

export default configuration;
