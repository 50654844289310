import { FunctionComponent, useEffect, useRef, useState } from "react";
import PageLayout from "@components/core/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { ImportFileCardStatus } from "@utils/enums/flipcard.enum";
import ImportFilesCard from "@components/core/cards/ImportFilesCard";
import IconVerifyDark from "@static/core/img/icon-verify-dark.svg?react";
import { UploadProps } from "antd";
import { requestPublicCheckDocumentAuthentication } from "@state/documents/DocumentEffects";
import { RcFile } from "antd/lib/upload";
import { DocumentAuthenticity } from "@utils/enums/document.authenticity.enum";
import { toastError } from "@utils/helpers/toast-helper";
import Captcha from "@components/core/captcha/Captcha";
import BasicButton from "@components/core/buttons/BasicButton";
import HCaptcha from "@hcaptcha/react-hcaptcha";

interface Document {
  document: Blob;
  filename: string;
}

const CheckAuthenticityScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const [document, setDocument] = useState<Document>();
  const hcaptchaRef = useRef<HCaptcha>(null);
  const [token, setToken] = useState<string>();

  const [verifyCardState, setVerifyCardState] =
    useState<ImportFileCardStatus>();

  const propsVerify: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      if (info.file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContentArray = new Uint8Array(
            event.target?.result as ArrayBuffer,
          );
          const fileBlob = new Blob([fileContentArray], {
            type: info.file.type,
          });
          setDocument({ document: fileBlob, filename: info.file.name });
          setVerifyCardState(ImportFileCardStatus.LOADED);
        };
        reader.readAsArrayBuffer(info.file as RcFile);
      } else {
        setVerifyCardState(ImportFileCardStatus.ERROR_NOT_PDF);
      }
    },
  };

  useEffect(() => {
    return requestPublicCheckDocumentAuthentication.done.watch(({ result }) => {
      if (result.responseCode === 403) {
        setVerifyCardState(ImportFileCardStatus.FORBIDDEN);
      } else if (result.responseCode !== 200 && result.responseCode != 401) {
        setVerifyCardState(ImportFileCardStatus.ERROR_DEFAULT);
      } else if (result.responseCode != 401) {
        if (
          result.data?.documentAuthenticityEnum === DocumentAuthenticity.VALID
        ) {
          setVerifyCardState(ImportFileCardStatus.VERIFIED_VALID);
        } else {
          setVerifyCardState(ImportFileCardStatus.VERIFIED_INVALID);
        }
      } else {
        toastError(t("error.401"));
      }
    });
  });

  const submitCheck = (): void => {
    if (token && document) {
      const formData = new FormData();
      formData.append("file-data", document.document, document.filename);
      void requestPublicCheckDocumentAuthentication({
        dto: formData,
        parameters: { token: token },
      }).finally(() => {
        setToken(undefined);
        setDocument(undefined);
        hcaptchaRef.current && hcaptchaRef.current.resetCaptcha();
      });
    }
  };

  return (
    <PageLayout container title={t("documents.verify.public.title")}>
      <div className="align-items-center d-flex flex-column gap-16 dark">
        <ImportFilesCard
          className="text-secondary"
          name="verify"
          icon={<IconVerifyDark />}
          module="documents.verify.flipcard"
          props={propsVerify}
          cardState={verifyCardState}
          onFlip={() => {
            setVerifyCardState(ImportFileCardStatus.DEFAULT);
          }}
        />
        <Captcha
          onVerify={setToken}
          innerRef={hcaptchaRef}
          className="d-flex justify-content-center mt-16"
        />
        <BasicButton
          text={t("documents.verify.public.actions.submit")}
          onClick={submitCheck}
          disabled={!token || !document}
          type="submit"
          variant="primary"
        />
      </div>
    </PageLayout>
  );
};

export default CheckAuthenticityScreen;
