import { FunctionComponent } from "react";

interface DividerProps {
  variant?: string;
  className?: string;
}

const BasicDivider: FunctionComponent<DividerProps> = ({
  variant = "primary",
  className = "",
}) => {
  return <div className={`app-divider divider-${variant} ${className}`} />;
};

export default BasicDivider;
