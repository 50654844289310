import { ColumnsType } from "antd/lib/table/interface";
import i18n from "i18next";
import DateFormat from "@utils/DateFormat";
import { DeviceDetailsResponseDto } from "@state/device/response/device.details.response.dto";

export const columns = (): ColumnsType<DeviceDetailsResponseDto> => [
  {
    title: i18n.t("devices.edit.table.fields.name"),
    dataIndex: "name",
  },
  {
    title: i18n.t("devices.edit.table.fields.statusDate"),
    dataIndex: "statusDate",
    render: (lastDate: string) => {
      return DateFormat.dashboardDate(new Date(lastDate));
    },
  },
];
