import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import DateFormat from "@utils/DateFormat";
import BasicLink from "@components/core/links/BasicLink";
import IconDownload from "@static/core/img/icon-download.svg?react";
import IconMore from "@static/core/img/icon-more.svg?react";
import { Popover } from "antd";
import {
  TransactionStatusEnum,
  transactionStatusProperties,
} from "@utils/enums/transactions/transaction.status.enum";
import { TransactionItemResponseDto } from "@state/transactions/dto/response/transaction.item.response.dto";
import { ROUTES } from "@routes/Routes";
import {
  requestDeleteSignedDocument,
  requestProofFileContent,
  requestRemindRecipient,
} from "@state/transactions/TransactionEffects";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { TransactionRoleEnum } from "@utils/enums/transactions/transaction.role.enum";

export const columns = (): ColumnsType<TransactionItemResponseDto> => [
  {
    title: i18n.t("dashboard.table.filename"),
    dataIndex: "transactionLabel",
    key: "name",
    showSorterTooltip: false,
    width: 450,
    render: (filename: string) => {
      return <span className={"column-break-line-all"}>{filename}</span>;
    },
  },
  {
    title: i18n.t("dashboard.table.depositDate"),
    dataIndex: "depositedOn",
    key: "createdAt",
    showSorterTooltip: false,
    render: (depositDate: string) => {
      return <div>{DateFormat.dashboardDate(new Date(depositDate))}</div>;
    },
  },
  {
    title: i18n.t("dashboard.table.depositedBy"),
    dataIndex: "submittedBy",
    key: "submittedBy",
    showSorterTooltip: false,
    width: 200,
    render: (filename: string) => {
      return <span>{filename}</span>;
    },
  },
  {
    title: i18n.t("dashboard.table.status"),
    dataIndex: "status",
    key: "status",
    showSorterTooltip: false,
    width: 200,
    render: (status: string, record: TransactionItemResponseDto) => (
      <div className="d-flex align-items-center justify-content-between gap-4">
        <span
          className={`text-${i18n.t(transactionStatusProperties[status].variant)}`}
        >
          {i18n.t(
            `transactions.status.${status === TransactionStatusEnum.COMPLETED.toString() ? "signed" : status === TransactionStatusEnum.REFUSED.toString() ? "refused" : "toSign"}.label`,
          )}
          {status === TransactionStatusEnum.COMPLETED.toString() &&
          record.signDate
            ? ` le ${DateFormat.dashboardDate(new Date(record.signDate))}`
            : ""}
        </span>
        {status !== TransactionStatusEnum.COMPLETED.toString() &&
          status !== TransactionStatusEnum.REFUSED.toString() && (
            <BasicLink
              to="#"
              onClick={() => {
                requestRemindRecipient({
                  upperEntityId: record.transactionId + "/relance",
                })
                  .then((resp) => {
                    if (resp.ok) {
                      toastSuccess(i18n.t("users.email.remind"));
                    }
                  })
                  .catch(() => {
                    toastError(
                      "Une erreur s'est produite lors de l'envoi des emails de relance aux signataires.",
                    );
                  });
              }}
            >
              {i18n.t("dashboard.relaunch")}
            </BasicLink>
          )}
      </div>
    ),
  },
  {
    title: i18n.t("dashboard.table.signedDocument"),
    key: "signedDocumentUrl",
    width: 350,
    render: (record: TransactionItemResponseDto) => {
      return (
        <div className="d-flex align-items-center gap-8">
          {record.status === TransactionStatusEnum.COMPLETED &&
          !record.expired ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={ROUTES.documentView.generate(
                record.transactionId,
                record.documents[0].documentId,
              )}
            >
              <IconDownload className="flex-shrink-0" />
            </a>
          ) : (
            <></>
          )}
          <span
            className={
              record.status !== TransactionStatusEnum.COMPLETED ||
              record.expired
                ? "font-italic font-weight-normal"
                : "font-normal font-weight-normal"
            }
          >
            {record.status === TransactionStatusEnum.COMPLETED &&
            !record.expired
              ? `${i18n.t("transactions.signedUrl.available")}${record.expirationDate && DateFormat.dashboardDate(new Date(record.expirationDate))}`
              : record.status === TransactionStatusEnum.COMPLETED &&
                  record.expired
                ? i18n.t("transactions.signedUrl.removed")
                : i18n.t("transactions.signedUrl.notAvailable")}
          </span>
        </div>
      );
    },
  },
  {
    title: "",
    width: 64,
    render: (record: TransactionItemResponseDto) => (
      <div className="d-flex align-items-center justify-content-end">
        {record.status === TransactionStatusEnum.COMPLETED &&
        (record.expired || record.role == TransactionRoleEnum.RECIPIENT) ? (
          <Popover
            content={
              <div
                className={"cursor-pointer"}
                onClick={() => {
                  void requestProofFileContent({
                    id: record.transactionId,
                  });
                }}
              >
                {i18n.t("dashboard.actions.downloadProofFile")}
              </div>
            }
            placement="bottomRight"
            trigger="hover"
            arrow={false}
          >
            <IconMore className="cursor-pointer" />
          </Popover>
        ) : record.status === TransactionStatusEnum.COMPLETED &&
          !record.expired &&
          record.role === TransactionRoleEnum.CREATOR ? (
          <Popover
            content={
              <>
                <div
                  className={"mb-16 cursor-pointer"}
                  onClick={() => {
                    void requestDeleteSignedDocument({
                      upperEntityId: record.transactionId,
                      id: record.documents[0].documentId + "/file",
                    });
                  }}
                >
                  {i18n.t("dashboard.actions.delete")}
                </div>
                <div
                  className={"mt-16 cursor-pointer"}
                  onClick={() => {
                    void requestProofFileContent({
                      id: record.transactionId,
                    });
                  }}
                >
                  {i18n.t("dashboard.actions.downloadProofFile")}
                </div>
              </>
            }
            placement="bottomRight"
            trigger="hover"
            arrow={false}
          >
            <IconMore className="cursor-pointer" />
          </Popover>
        ) : null}
      </div>
    ),
  },
];
