import { restPostHandler } from "@utils/rest/RestClient";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { UserDetailsResponseDto } from "@state/users/dto/response/user.details.response.dto";
import { UserSelfUpdateRequestDto } from "@state/users/dto/request/user.self.update.request.dto";
import {
  userUpdateResponseDtoDecoder,
  userUpdateResponseDtoDecoderContent,
} from "@state/users/dto/response/user.update.response.dto";
import { PasswordUpdateRequestDto } from "@state/users/dto/request/password.update.request.dto";

const baseUrl = configuration.backendBaseUrl;
const usersUrl = baseUrl + "/users/";

export const requestUpdateUserPassword = createEffect({
  handler: restPostHandler<PasswordUpdateRequestDto>(
    usersUrl + "update-password",
  ),
});

export const requestSelfUpdateUser = createEffect({
  handler: restPostHandler<
    UserSelfUpdateRequestDto,
    UserDetailsResponseDto,
    typeof userUpdateResponseDtoDecoderContent,
    typeof userUpdateResponseDtoDecoder
  >(usersUrl + "me", undefined, userUpdateResponseDtoDecoder),
});
