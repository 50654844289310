import { ChangeEvent, FunctionComponent } from "react";
import { Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import Search from "antd/es/input/Search";
import { formHelper } from "@utils/helpers/form-helper";
import { basicMandatoryRule } from "@components/core/inputs/InputFormRules";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import FloatingLabel from "@components/core/inputs/FloatingLabel";

export interface PropsType {
  showTooltip?: boolean;
  labelTooltip?: string | null;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onSearch?: (value: string) => void;
  disabled?: boolean;
  value?: string;
  form: FormInstance;
}

const SearchFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    showTooltip,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName = "",
    fieldClassName = "",
    onChange,
    maxLength = 100,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onSearch,
    disabled,
    value,
    form,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const inputPlaceholder =
    placeholder ?? t(`${module}.fields.${i18nField}.placeholder`);

  return (
    <FloatingLabel
      form={form}
      field={`${fieldPrefix}${String(field)}`}
      label={
        <FormFieldLabel
          field={field}
          module={module}
          label={inputPlaceholder}
          className={labelClassName}
          showTooltip={showTooltip}
          tooltipLabel={labelTooltip}
        />
      }
    >
      <Form.Item
        name={`${fieldPrefix}${String(field)}`}
        key={key}
        rules={[
          basicMandatoryRule({
            field,
            emptyErrorMessage,
            constraints: { isRequired, isBlankAllowed: true },
          }),
          ...rules,
        ]}
        className={`search ${className}`}
      >
        <Search
          disabled={readOnly ?? disabled}
          className={`${readOnly ? "readonly-form-field" : ""} ${fieldClassName}`}
          placeholder={inputPlaceholder}
          id={key}
          onChange={onChange}
          maxLength={maxLength}
          onSearch={onSearch}
          value={value}
          data-test-type="input-text"
          data-test={field}
        />
      </Form.Item>
    </FloatingLabel>
  );
};

export default SearchFormField;
