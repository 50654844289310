import { FunctionComponent } from "react";
import { DatePicker, Form, FormInstance, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath, Rule } from "rc-field-form/lib/interface";
import { dateFormat } from "@utils/Constant";
import { formHelper } from "@utils/helpers/form-helper";
import { basicMandatoryRule } from "@components/core/inputs/InputFormRules";
import { DateTime } from "luxon";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import RangePickerLabel from "@components/core/inputs/RangePickerLabel";
const { RangePicker } = DatePicker;

interface PropsType {
  showTooltip?: boolean;
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  placeholder?: [string, string];
  labelTooltip?: string | null;
  readOnly?: boolean;
  rules?: Rule[];
  required?: boolean;
  emptyErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  picker?: "week" | "month" | "quarter" | "year" | "date";
  disabledDate?: (date: DateTime) => boolean;
  fieldPrefix?: string;
  disabled?: boolean;
  form: FormInstance;
}

const RangePickerFormField: FunctionComponent<PropsType> = ({
  showTooltip,
  showLabel,
  labelTooltip,
  module,
  field,
  readOnly,
  className = "",
  labelClassName = "",
  fieldClassName = "",
  rules = [],
  required = false,
  emptyErrorMessage,
  disabledDate,
  picker = "date",
  fieldPrefix = "",
  disabled,
  placeholder,
  form,
}) => {
  const { t } = useTranslation();

  const isRequired: boolean = readOnly ? false : required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const inputPlaceholder = t(`${module}.fields.${i18nField}.placeholder`);

  return (
    <RangePickerLabel
      form={form}
      field={`${fieldPrefix}${String(field)}`}
      label={
        <FormFieldLabel
          field={field}
          module={module}
          label={inputPlaceholder}
          className={labelClassName}
          showTooltip={showTooltip}
          tooltipLabel={labelTooltip}
        />
      }
    >
      <Form.Item
        name={`${fieldPrefix}${String(field)}`}
        key={key}
        label={
          readOnly ?? showLabel ? (
            <span className={labelClassName}>
              {t(`${module}.fields.${i18nField}.label`)}&nbsp;
              {labelTooltip && <Tooltip title={labelTooltip} />}
            </span>
          ) : (
            ""
          )
        }
        rules={[
          basicMandatoryRule({
            field,
            emptyErrorMessage,
            constraints: { isRequired },
          }),
          ...rules,
        ]}
        className={className}
      >
        <RangePicker
          picker={picker}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentElement ? triggerNode.parentElement : triggerNode
          }
          disabled={readOnly ?? disabled}
          id={key}
          placeholder={
            placeholder ?? [
              t(`${module}.fields.${i18nField}.placeholderStart`),
              t(`${module}.fields.${i18nField}.placeholderEnd`),
            ]
          }
          showTime={false}
          disabledDate={(current) => {
            return disabledDate
              ? disabledDate(DateTime.fromJSDate(current.toDate()))
              : DateTime.fromJSDate(current.toDate()) > DateTime.now();
          }}
          format={dateFormat}
          className={fieldClassName}
        />
      </Form.Item>
    </RangePickerLabel>
  );
};

export default RangePickerFormField;
