import {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@routes/Routes";
import BasicLink from "@components/core/links/BasicLink";
import SideMenu from "@components/core/menus/SideMenu";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";
import BasicButton from "@components/core/buttons/BasicButton";
import { CloseOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { MENU } from "@utils/enums/menu.enum";
import { Dropdown } from "antd";
import { FeatureItem } from "@type/core/feature/feature.types";
import { ItemType } from "antd/es/menu/hooks/useItems";

interface HeaderProps {
  className?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const authenticationContext = useUnit(authenticationStore);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [selectedParentKey, setSelectedParentKey] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState<string>("");

  useEffect(() => {
    MENU.forEach((item) => {
      if (item.link && window.location.pathname === item.link) {
        setSelectedParentKey(item.key);
        setSelectedKey("");
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (child.link && window.location.pathname === child.link) {
            setSelectedParentKey(item.key);
            setSelectedKey(child.key);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const buildItemFrom = (item: FeatureItem): ReactElement => {
    return (
      <>
        {item.icon && <div className="icon">{item.icon}</div>}
        <span className="text">{t(item.text)}</span>
      </>
    );
  };

  const computeMenuItem = (item: FeatureItem, className?: string): ItemType => {
    return {
      key: item.key,
      label: item.link ? (
        <BasicLink
          to={item.link}
          className={selectedKey === item.key ? "link-active" : ""}
          title={t(item.text)}
          onClick={item.onClick}
        >
          {buildItemFrom(item)}
        </BasicLink>
      ) : (
        <div
          className={`link ${
            selectedKey === item.key ? "link-active" : ""
          } ${className ?? ""}`}
        >
          {buildItemFrom(item)}
        </div>
      ),
    };
  };

  const computeMenuWithChildren = (
    item: FeatureItem,
    className?: string,
  ): ReactNode => {
    return (
      <Dropdown
        key={item.key}
        placement="bottomRight"
        menu={{
          className: "header-link-dropdown",
          items:
            item.children && item.children.length > 0
              ? [
                  item.key === "account" && authenticationContext?.user
                    ? {
                        key: "name",
                        label: (
                          <div className="dropdown-account-name">{`${authenticationContext.user.firstName} ${authenticationContext.user.lastName}`}</div>
                        ),
                      }
                    : null,
                  ...item.children.map((childMenuElement) =>
                    computeMenuItem(childMenuElement),
                  ),
                ]
              : [],
        }}
      >
        {item.link ? (
          <BasicLink
            to={item.link}
            onClick={item.onClick}
            title={t(item.text)}
            className={`link ${
              selectedParentKey === item.key ? "link-active" : ""
            } ${className ?? ""}`}
          >
            <>
              {buildItemFrom(item)}
              <div className="icon-dropdown">
                <DownOutlined />
              </div>
            </>
          </BasicLink>
        ) : (
          <div
            className={`link ${
              selectedParentKey === item.key ? "link-active" : ""
            } ${className ?? ""}`}
          >
            {buildItemFrom(item)}
            <div className="icon-dropdown">
              <DownOutlined />
            </div>
          </div>
        )}
      </Dropdown>
    );
  };

  const computeMenu = (item: FeatureItem, className?: string): ReactNode => {
    return item.link ? (
      <BasicLink
        key={item.key}
        to={item.link}
        onClick={item.onClick}
        title={t(item.text)}
        className={`link ${
          selectedParentKey === item.key ? "link-active" : ""
        } ${className ?? ""}`}
      >
        {buildItemFrom(item)}
      </BasicLink>
    ) : (
      <div key={item.key} className={`link ${className ?? ""}`}>
        {buildItemFrom(item)}
      </div>
    );
  };

  return (
    <>
      <header className={className}>
        <div className="header-container content-contained content-xl">
          <div className="logo-container">
            <BasicLink to={ROUTES.connected.home.generate()}>
              <img
                alt={t("header.logo.alt")}
                className="logo"
                src="/img/logo.svg"
              />
            </BasicLink>
          </div>
          {authenticationContext?.user ? (
            <div className="content-container">
              <div className="links">
                {MENU.map((item) => {
                  return item.children && item.children.length > 0
                    ? computeMenuWithChildren(item)
                    : computeMenu(item);
                })}
              </div>
              <div className="menu">
                <BasicButton
                  variant="side-menu"
                  icon={isSideMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
                  onClick={() => {
                    setIsSideMenuOpen(!isSideMenuOpen);
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </header>
      {authenticationContext?.user && (
        <SideMenu
          isSideMenuOpen={isSideMenuOpen}
          selectedParentKey={selectedParentKey}
          selectedKey={selectedKey}
        />
      )}
    </>
  );
};

export default Header;
