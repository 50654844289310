import { JSX } from "react";
import IconVerifyInvalid from "@static/core/img/icon-verify-invalid.svg?react";
import IconVerify from "@static/core/img/icon-verify.svg?react";
import IconWarning from "@static/core/img/icon-warning.svg?react";
import IconSignature from "@static/core/img/icon-signature.svg?react";
import IconTransfer from "@static/core/img/icon-transfer.svg?react";

export enum ImportFileCardStatus {
  DEFAULT = "DEFAULT",
  ERROR_DEFAULT = "ERROR_DEFAULT",
  VERIFIED_INVALID = "VERIFIED_INVALID",
  VERIFIED_VALID = "VERIFIED_VALID",
  FORBIDDEN = "FORBIDDEN",
  SUCCESS = "SUCCESS",
  ERROR_NOT_PDF = "ERROR_NOT_PDF",
  LOADED = "LOADED",
}

export interface ImportFileCardStatusProperties {
  key: ImportFileCardStatus;
  flipped: boolean;
  variant: string;
  icon?: JSX.Element;
}

export const importFileCardStatusProperties: Record<
  ImportFileCardStatus,
  ImportFileCardStatusProperties
> = {
  [ImportFileCardStatus.DEFAULT]: {
    key: ImportFileCardStatus.DEFAULT,
    flipped: false,
    variant: "primary",
  },
  [ImportFileCardStatus.ERROR_DEFAULT]: {
    key: ImportFileCardStatus.ERROR_DEFAULT,
    flipped: true,
    variant: "error",
    icon: <IconWarning />,
  },
  [ImportFileCardStatus.FORBIDDEN]: {
    key: ImportFileCardStatus.FORBIDDEN,
    flipped: true,
    variant: "error",
    icon: <IconWarning />,
  },
  [ImportFileCardStatus.ERROR_NOT_PDF]: {
    key: ImportFileCardStatus.ERROR_NOT_PDF,
    flipped: true,
    variant: "error",
    icon: <IconWarning />,
  },
  [ImportFileCardStatus.VERIFIED_INVALID]: {
    key: ImportFileCardStatus.VERIFIED_INVALID,
    flipped: true,
    variant: "warning",
    icon: <IconVerifyInvalid />,
  },
  [ImportFileCardStatus.SUCCESS]: {
    key: ImportFileCardStatus.SUCCESS,
    flipped: true,
    variant: "success",
    icon: <IconSignature />,
  },
  [ImportFileCardStatus.VERIFIED_VALID]: {
    key: ImportFileCardStatus.VERIFIED_VALID,
    flipped: true,
    variant: "success",
    icon: <IconVerify />,
  },
  [ImportFileCardStatus.LOADED]: {
    key: ImportFileCardStatus.LOADED,
    flipped: false,
    variant: "primary",
    icon: <IconTransfer />,
  },
} as const;
