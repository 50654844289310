import account from "./account.json";
import api from "./api.json";
import banners from "./banners.json";
import components from "./components.json";
import cookies from "./cookies.json";
import dashboard from "./dashboard.json";
import deeplink from "./deeplink.json";
import error from "./error.json";
import forms from "./forms.json";
import header from "./header.json";
import images from "./images.json";
import imports from "./imports.json";
import login from "./login.json";
import table from "./table.json";
import users from "./users.json";
import footer from "./footer.json";
import files from "./files.json";
import licenses from "./licenses.json";
import transactions from "./transactions.json";
import events from "./events.json";
import documents from "./documents.json";
import devices from "./devices.json";
import authorizations from "./authorizations.json";

const coreTranslationFR = {
  ...account,
  ...api,
  ...banners,
  ...components,
  ...cookies,
  ...dashboard,
  ...deeplink,
  ...error,
  ...forms,
  ...header,
  ...images,
  ...imports,
  ...login,
  ...table,
  ...users,
  ...footer,
  ...files,
  ...licenses,
  ...transactions,
  ...events,
  ...documents,
  ...devices,
  ...authorizations,
};

export default coreTranslationFR;
