import { FunctionComponent } from "react";

const E2EHook: FunctionComponent = () => {
  return (
    <img
      data-test="e2e-hook"
      className="e2e-hook"
      src="/img/core/e2e/e2e-hook.webp"
      alt="Hook e2e"
    />
  );
};

export default E2EHook;
