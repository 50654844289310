import { FunctionComponent, JSX } from "react";
import Section from "@components/core/section/Section";
import BasicCard from "@components/core/cards/BasicCard";

interface CardSectionProps {
  title?: string;
  rightContent?: string | JSX.Element;
  description?: string;
  children: string | JSX.Element;
  className?: string;
  titleClassName?: string;
  cardClassName?: string;
}

const CardSection: FunctionComponent<CardSectionProps> = ({
  title,
  rightContent,
  description,
  children,
  className = "",
  titleClassName = "",
  cardClassName = "",
}) => {
  return (
    <Section
      title={title}
      titleClassName={titleClassName}
      rightContent={rightContent}
      description={description}
      className={`app-section-card ${className}`}
    >
      <BasicCard className={cardClassName}>{children}</BasicCard>
    </Section>
  );
};

export default CardSection;
