import { FunctionComponent, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BasicLink from "@components/core/links/BasicLink";
import { FeatureItem } from "@type/core/feature/feature.types";
import { MENU } from "@utils/enums/menu.enum";
import { BREAKPOINTS, useViewport } from "@utils/helpers/breakpoints-helper";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";

interface SideMenuProps {
  isSideMenuOpen: boolean;
  selectedParentKey: string;
  selectedKey: string;
  className?: string;
}

const SideMenu: FunctionComponent<SideMenuProps> = ({
  isSideMenuOpen,
  selectedParentKey,
  selectedKey,
  className = "",
}) => {
  const { t } = useTranslation();
  const { width } = useViewport();
  const authenticationContext = useUnit(authenticationStore);

  useEffect(() => {
    const body = document.body;
    if (isSideMenuOpen && width < BREAKPOINTS.lg) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  }, [isSideMenuOpen, width]);

  const buildItemFrom = (item: FeatureItem): ReactElement => {
    return (
      <>
        {item.icon && <div className="icon">{item.icon}</div>}
        <span className="text">{t(item.text)}</span>
      </>
    );
  };

  const computeMenuWithChildren = (item: FeatureItem, className?: string) => {
    return (
      <div key={item.key}>
        <div className="link-container">
          {item.link ? (
            <BasicLink
              to={item.link}
              title={t(item.text)}
              className={`link ${
                selectedParentKey === item.key ? "link-active" : ""
              } ${className ?? ""}`}
            >
              {buildItemFrom(item)}
            </BasicLink>
          ) : (
            <div
              className={`link ${
                selectedParentKey === item.key ? "link-active" : ""
              } ${className ?? ""}`}
            >
              {buildItemFrom(item)}
            </div>
          )}
        </div>
        <div className="sub-link-container">
          <>
            {item.key === "account" && authenticationContext?.user && (
              <div className="sub-account-name">{`${authenticationContext.user.firstName} ${authenticationContext.user.lastName}`}</div>
            )}
            {item.children?.map((item) => (
              <div key={item.key} className="sub-link">
                {item.link ? (
                  <BasicLink
                    to={item.link}
                    title={t(item.text)}
                    className={selectedKey === item.key ? "link-active" : ""}
                  >
                    {buildItemFrom(item)}
                  </BasicLink>
                ) : (
                  <div
                    key={item.key}
                    className={`link ${
                      selectedKey === item.key ? "link-active" : ""
                    }`}
                  >
                    {buildItemFrom(item)}
                  </div>
                )}
              </div>
            ))}
          </>
        </div>
      </div>
    );
  };

  const computeMenu = (item: FeatureItem, className?: string) => {
    return item.link ? (
      <BasicLink
        key={item.key}
        to={item.link}
        title={t(item.text)}
        className={`link ${
          selectedParentKey === item.key ? "link-active" : ""
        } ${className ?? ""}`}
      >
        {buildItemFrom(item)}
      </BasicLink>
    ) : (
      <div key={item.key} className={`link ${className ?? ""}`}>
        {buildItemFrom(item)}
      </div>
    );
  };

  return (
    <div
      className={`app-sidemenu ${
        isSideMenuOpen ? "sidemenu-open" : ""
      } ${className}`}
    >
      <div className="sidemenu-container">
        <div className="links">
          {MENU.map((item) =>
            item.children && item.children.length > 0
              ? computeMenuWithChildren(item)
              : computeMenu(item),
          )}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
