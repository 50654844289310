import { FunctionComponent, useCallback, useEffect, useState } from "react";
import PageLayout from "@components/core/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import {
  Col,
  Form,
  Row,
  Spin,
  Table,
  TablePaginationConfig,
  UploadProps,
} from "antd";
import { formValidateTriggers } from "@utils/Constant";
import InputFormField from "@components/core/inputs/InputFormField";
import { SelectFormField } from "@components/core/inputs/SelectFormField";
import Section from "@components/core/section/Section";
import { useForm } from "antd/lib/form/Form";
import SubmitButton from "@components/core/buttons/SubmitButton";
import BasicButton from "@components/core/buttons/BasicButton";
import { CloseOutlined } from "@ant-design/icons";
import ImportFilesCard from "@components/core/cards/ImportFilesCard";
import IconSignature from "@static/core/img/icon-signature.svg?react";
import IconVerify from "@static/core/img/icon-verify.svg?react";
import { columns } from "@views/core/admin/home/columns/DashboardColumns";
import { Pageable } from "@type/core/list/pagination.types";
import {
  TRANSACTION_STATUS_OPTIONS,
  TransactionStatusEnum,
} from "@utils/enums/transactions/transaction.status.enum";
import {
  requestCreateTransaction,
  requestDeleteSignedDocument,
  requestGetDashboardDocuments,
  requestGetDocumentContent,
  requestGetSubmitterNames,
  requestProofFileContent,
  requestRemindRecipient,
} from "@state/transactions/TransactionEffects";
import { toastError } from "@utils/helpers/toast-helper";
import { TransactionItemResponseDto } from "@state/transactions/dto/response/transaction.item.response.dto";
import {
  downloadFile,
  getBase64UrlFrom,
  openFilePreview,
} from "@utils/helpers/base64-helper";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { MetaData } from "@state/transactions/dto/request/transaction.save.metadata.dto";
import DateFormat from "@utils/DateFormat";
import { requestCheckDocumentAuthentication } from "@state/documents/DocumentEffects";
import { DocumentAuthenticity } from "@utils/enums/document.authenticity.enum";
import { RcFile } from "antd/lib/upload";
import RangePickerFormField from "@components/core/inputs/RangePickerFormField";
import { OptionType } from "@type/core/form/field.types";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";
import { UserProfile } from "@utils/enums/profile.enum";
import { ImportFileCardStatus } from "@utils/enums/flipcard.enum";

interface FormValues {
  query: string;
  status: string;
  depositedBy: string;
  depositedOn: Date[];
  signedOn: Date[];
}

const InitialValues = {
  query: "",
  status: "",
  depositedOn: null,
  signedOn: null,
  depositedBy: "",
};

const DashboardScreen: FunctionComponent = () => {
  const [form] = useForm();
  const { t } = useTranslation();

  const authenticationContext = useUnit(authenticationStore);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [transactions, setTransactions] = useState<
    Pageable<TransactionItemResponseDto>
  >({
    size: 10,
    totalElements: 0,
    content: [],
    numberOfElements: 0,
    totalPages: 0,
    number: 0,
  });

  const [isFilterFilled, setIsFilterFilled] = useState(false);

  const [signatureCardState, setSignatureCardState] =
    useState<ImportFileCardStatus>();
  const [verifyCardState, setVerifyCardState] =
    useState<ImportFileCardStatus>();

  const [submitterNames, setSubmitterNames] = useState<OptionType[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<
    | SorterResult<TransactionItemResponseDto>
    | SorterResult<TransactionItemResponseDto>[]
  >({
    columnKey: "createdAt",
    order: "descend",
  });

  const searchTransactionsAllowed =
    authenticationContext?.user?.profiles.includes(UserProfile.VETERINARY);

  const handleSubmit = () => {
    setButtonLoading(true);
    resetPage();
    fetchDocuments();
  };

  const handleReset = () => {
    form.resetFields();
    setIsFilterFilled(false);
    resetPage();
    fetchDocuments();
  };

  const resetPage = () => {
    handlePageChange(1);
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
    }
  };

  const propsImport: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      if (info.file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContentArray = new Uint8Array(
            event.target?.result as ArrayBuffer,
          );
          const metaData: MetaData = {
            "file-name": "info?.file.name + ",
            "document-type": "DOCUMENT",
          };
          const formData = new FormData();
          formData.append("meta-data", JSON.stringify(metaData));

          const fileBlob = new Blob([fileContentArray], {
            type: info.file.type,
          });
          formData.append("file-data", fileBlob, info.file.name);
          void requestCreateTransaction({ dto: formData });
        };
        reader.readAsArrayBuffer(info.file as RcFile);
      } else {
        setSignatureCardState(ImportFileCardStatus.ERROR_NOT_PDF);
      }
    },
  };

  const propsVerify: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      if (info.file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContentArray = new Uint8Array(
            event.target?.result as ArrayBuffer,
          );
          const formData = new FormData();
          const fileBlob = new Blob([fileContentArray], {
            type: info.file.type,
          });
          formData.append("file-data", fileBlob, info.file.name);

          void requestCheckDocumentAuthentication({ dto: formData });
        };
        reader.readAsArrayBuffer(info.file as RcFile);
      } else {
        setVerifyCardState(ImportFileCardStatus.ERROR_NOT_PDF);
      }
    },
  };

  const fetchRequesterNames = useCallback(() => {
    requestGetSubmitterNames({}).catch(() => {
      toastError(t("events.list.messages.loading-error"));
    });
  }, [t]);

  useEffect(() => {
    if (searchTransactionsAllowed) {
      fetchRequesterNames();
    }
  }, [fetchRequesterNames, searchTransactionsAllowed]);

  useEffect(() => {
    return requestCreateTransaction.done.watch(({ result }) => {
      if (result.responseCode === 403) {
        setSignatureCardState(ImportFileCardStatus.FORBIDDEN);
      } else if (result.responseCode !== 201 && result.responseCode != 401) {
        setSignatureCardState(ImportFileCardStatus.ERROR_DEFAULT);
      } else if (result.responseCode != 401) {
        fetchRequesterNames();
        setSignatureCardState(ImportFileCardStatus.SUCCESS);
        resetPage();
        fetchDocuments();
      } else {
        toastError(t("error.401"));
      }
    });
  });

  useEffect(() => {
    return requestCheckDocumentAuthentication.done.watch(({ result }) => {
      if (result.responseCode === 403) {
        setVerifyCardState(ImportFileCardStatus.FORBIDDEN);
      } else if (result.responseCode !== 200 && result.responseCode != 401) {
        setVerifyCardState(ImportFileCardStatus.ERROR_DEFAULT);
      } else if (result.responseCode != 401) {
        if (
          result.data?.documentAuthenticityEnum === DocumentAuthenticity.VALID
        ) {
          setVerifyCardState(ImportFileCardStatus.VERIFIED_VALID);
        } else {
          setVerifyCardState(ImportFileCardStatus.VERIFIED_INVALID);
        }
      } else {
        toastError(t("error.401"));
      }
    });
  });

  useEffect(() => {
    return requestGetDashboardDocuments.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setTransactions(result.data);
      } else if (result.responseCode != 401) {
        toastError(t("events.list.messages.loading-error"));
      } else {
        toastError(t("error.401"));
      }
      setIsLoading(false);
      setButtonLoading(false);
    });
  });

  useEffect(() => {
    return requestGetDocumentContent.done.watch(({ result }) => {
      if (result.ok && result.data) {
        openFilePreview(result.data.base64Data, result.data.mimeType);
      }
    });
  });

  useEffect(() => {
    return requestGetSubmitterNames.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setSubmitterNames(result.data);
      } else if (result.responseCode != 401) {
        toastError(t("events.list.messages.loading-error"));
      } else {
        toastError(t("error.401"));
      }
    });
  });

  useEffect(() => {
    return requestProofFileContent.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const url = getBase64UrlFrom(
          result.data.base64Data,
          result.data.mimeType,
        );
        downloadFile(url, result.data.filename);
      } else {
        toastError(
          t("transactions.proof.error", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  useEffect(() => {
    return requestRemindRecipient.done.watch(() => {
      resetPage();
      fetchDocuments();
    });
  });

  useEffect(() => {
    return requestDeleteSignedDocument.done.watch(({ result }) => {
      if (result.ok) {
        resetPage();
        fetchDocuments();
      } else {
        toastError(t("documents.sign.messages.error"));
      }
    });
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<TransactionItemResponseDto>
      | SorterResult<TransactionItemResponseDto>[],
  ) => {
    if ((sorter as SorterResult<TransactionItemResponseDto>).column) {
      setSorter(sorter);
    }
  };

  const fetchDocuments = useCallback(() => {
    setIsLoading(true);
    const depositedOn = form.getFieldValue("depositedOn") as Date[];
    const signedOn = form.getFieldValue("signedOn") as Date[];
    const query = form.getFieldValue("query") as string;
    const status = form.getFieldValue("status") as string;
    const depositedBy = form.getFieldValue("depositedBy") as string;

    requestGetDashboardDocuments({
      page: page - 1,
      limit: 10,
      sorter: sorter,
      dto: {
        query: query !== "" ? query : undefined,
        depositedOn:
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          depositedOn !== null && depositedOn.length === 2
            ? `${DateFormat.dashboardDate(new Date(depositedOn[0]))}-${DateFormat.dashboardDate(new Date(depositedOn[1]))}`
            : undefined,
        depositedBy: depositedBy,
        signedOn:
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          signedOn !== null && signedOn.length === 2
            ? `${DateFormat.dashboardDate(new Date(signedOn[0]))}-${DateFormat.dashboardDate(new Date(signedOn[1]))}`
            : undefined,
        status: status,
        excludeCreated: true,
      },
    }).catch(() => {
      toastError(t("events.list.messages.loading-error"));
    });
  }, [form, page, sorter, t]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleFilterChange = () => {
    const allValues = form.getFieldsValue() as FormValues;
    fetchDocuments();
    setIsFilterFilled(
      Object.values(allValues).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== null && value !== undefined && value !== "";
      }),
    );
  };

  return (
    <PageLayout
      container
      headerContent={
        <div className="d-flex flex-column align-items-stretch gap-32">
          <div className="d-flex align-items-stretch justify-content-center flex-wrap gap-48 h-100">
            <ImportFilesCard
              name="signature"
              icon={<IconSignature />}
              module="transactions.edit.modals.save.flipcard"
              props={propsImport}
              multiple={false}
              cardState={signatureCardState}
              onFlip={() => {
                setSignatureCardState(ImportFileCardStatus.DEFAULT);
              }}
            />
            <ImportFilesCard
              name="verify"
              icon={<IconVerify />}
              module="documents.verify.flipcard"
              props={propsVerify}
              cardState={verifyCardState}
              onFlip={() => {
                setVerifyCardState(ImportFileCardStatus.DEFAULT);
              }}
            />
          </div>
        </div>
      }
    >
      <Section title={t("dashboard.form.title")}>
        <>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={InitialValues}
            onValuesChange={handleFilterChange}
            {...formValidateTriggers}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col xs={24} sm={14} lg={16}>
                <InputFormField
                  form={form}
                  module="dashboard.form"
                  field="query"
                  onChange={resetPage}
                />
              </Col>
              <Col xs={24} sm={10} lg={8}>
                <SelectFormField
                  form={form}
                  module="dashboard.form"
                  field="status"
                  allowClear
                  options={TRANSACTION_STATUS_OPTIONS}
                />
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <RangePickerFormField
                  form={form}
                  module="dashboard.form"
                  field="depositedOn"
                />
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <SelectFormField
                  form={form}
                  module="dashboard.form"
                  field="depositedBy"
                  allowClear
                  options={submitterNames}
                />
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <RangePickerFormField
                  form={form}
                  module="dashboard.form"
                  field="signedOn"
                />
              </Col>
            </Row>
            <div className="pt-8 d-flex align-items-center justify-content-end flex-wrap gap-12">
              {isFilterFilled && (
                <BasicButton
                  variant="transparent"
                  size="sm"
                  icon={<CloseOutlined />}
                  iconRight
                  text={t("dashboard.form.reset")}
                  onClick={handleReset}
                  className="px-0"
                />
              )}
              <SubmitButton
                variant="primary-outlined"
                size="sm"
                module="dashboard.form"
                label="submit"
                disabled={!searchTransactionsAllowed}
                isSubmitting={buttonLoading}
              />
            </div>
          </Form>
          <div className="mt-24">
            <Table
              className="app-table"
              locale={{
                emptyText: isLoading
                  ? t("table.emptyText.loading")
                  : t("table.emptyText.noData"),
                triggerAsc: t("table.sort.asc"),
                triggerDesc: t("table.sort.desc"),
                cancelSort: t("table.sort.cancel"),
              }}
              loading={{ spinning: isLoading, indicator: <Spin /> }}
              pagination={{
                total: transactions.totalElements,
                pageSize: transactions.size,
                current: transactions.number + 1,
                onChange: (pageNumber: number) => {
                  handlePageChange(pageNumber);
                },
              }}
              columns={columns()}
              dataSource={transactions.content}
              rowKey={(transaction) => transaction.transactionId}
              rowClassName={(record: TransactionItemResponseDto) =>
                record.status !== TransactionStatusEnum.COMPLETED &&
                record.status !== TransactionStatusEnum.REFUSED
                  ? "row-to-sign"
                  : ""
              }
              onChange={handleTableChange}
            />
          </div>
        </>
      </Section>
    </PageLayout>
  );
};

export default DashboardScreen;
