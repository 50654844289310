import { JSX } from "react";
import { OptionType } from "@type/core/form/field.types";
import { UserOutlined, LinuxOutlined } from "@ant-design/icons";

export enum UserProfile {
  VETERINARY = "VETERINARY",
  OTHER = "OTHER",
  DEVELOPER = "DEVELOPER",
}
const profilesWithSelfRegistration = [
  UserProfile.VETERINARY,
  UserProfile.OTHER,
];
const profilesWithOrdinalNumberRequired = [UserProfile.VETERINARY];

export const findUserProfile = (
  rawProfile?: string | null,
): UserProfile | undefined =>
  rawProfile && Object.values(UserProfile).includes(rawProfile as UserProfile)
    ? (rawProfile as UserProfile)
    : undefined;

const hasCommonProfile = (
  testedProfiles: UserProfile[],
  referenceProfiles: UserProfile[],
): boolean => {
  return testedProfiles.some((testedProfile) =>
    referenceProfiles.includes(testedProfile),
  );
};

export const canSelfRegister = (profiles: UserProfile[]): boolean => {
  return hasCommonProfile(profiles, profilesWithSelfRegistration);
};

export const requireOrdinalNumber = (profiles: UserProfile[]): boolean => {
  return hasCommonProfile(profiles, profilesWithOrdinalNumberRequired);
};

export interface UserProfileProperties {
  id: string;
  label: string;
  icon: JSX.Element;
  description: string;
  level: number;
}

export const userProfilesProperties: Record<
  UserProfile,
  UserProfileProperties
> = {
  [UserProfile.VETERINARY]: {
    id: UserProfile.VETERINARY,
    label: "users.labels.profiles.VETERINARY.label",
    icon: <LinuxOutlined />,
    description: "users.labels.profiles.VETERINARY.description",
    level: 1,
  },
  [UserProfile.OTHER]: {
    id: UserProfile.OTHER,
    label: "users.labels.profiles.OTHER.label",
    icon: <UserOutlined />,
    description: "users.labels.profiles.OTHER.description",
    level: 2,
  },
  [UserProfile.DEVELOPER]: {
    id: UserProfile.DEVELOPER,
    label: "users.labels.profiles.DEVELOPER.label",
    icon: <UserOutlined />,
    description: "users.labels.profiles.DEVELOPER.description",
    level: 3,
  },
} as const;

export const PROFILES_OPTIONS = Object.keys(UserProfile)
  .map((profile) => profile as UserProfile)
  .map((profileId) => ({
    value: profileId,
    label: userProfilesProperties[profileId].label,
  })) as OptionType[];
