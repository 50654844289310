import { createEffect } from "effector";
import { restGetUniqueHandler } from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import {
  DeviceDetailsResponseDto,
  deviceDetailsResponseDtoDecoder,
  deviceDetailsResponseDtoDecoderContent,
} from "@state/device/response/device.details.response.dto";

const deviceBaseUrl = `${configuration.backendBaseUrl}/enrollment/devices`;

export const requestGetUserDeviceInfo = createEffect({
  handler: restGetUniqueHandler<
    DeviceDetailsResponseDto,
    Record<string, unknown>,
    typeof deviceDetailsResponseDtoDecoderContent,
    typeof deviceDetailsResponseDtoDecoder
  >(deviceBaseUrl + "/", undefined, deviceDetailsResponseDtoDecoder),
});
