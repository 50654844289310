import { FunctionComponent } from "react";
import { DatePicker, Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath, Rule } from "rc-field-form/lib/interface";
import { dateFormat } from "@utils/Constant";
import { formHelper } from "@utils/helpers/form-helper";
import { basicMandatoryRule } from "@components/core/inputs/InputFormRules";
import { DateTime } from "luxon";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import FloatingLabel from "@components/core/inputs/FloatingLabel";

interface PropsType {
  showTooltip?: boolean;
  module: string;
  field: string | InternalNamePath;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  rules?: Rule[];
  required?: boolean;
  emptyErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  onChange?: (value: DateTime | null) => void;
  picker?: "week" | "month" | "quarter" | "year" | "date";
  disabledDate?: (date: DateTime) => boolean;
  fieldPrefix?: string;
  disabled?: boolean;
  inModal?: boolean;
  form: FormInstance;
}

const DatePickerFormField: FunctionComponent<PropsType> = ({
  showTooltip,
  labelTooltip,
  module,
  field,
  readOnly,
  className = "",
  labelClassName = "",
  fieldClassName = "",
  rules = [],
  required = false,
  emptyErrorMessage,
  disabledDate,
  picker = "date",
  fieldPrefix = "",
  disabled,
  placeholder,
  inModal,
  form,
}) => {
  const { t } = useTranslation();

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const inputPlaceholder =
    placeholder ?? t(`${module}.fields.${i18nField}.placeholder`);

  return (
    <FloatingLabel
      form={form}
      field={`${fieldPrefix}${String(field)}`}
      label={
        <FormFieldLabel
          field={field}
          module={module}
          label={inputPlaceholder}
          className={labelClassName}
          showTooltip={showTooltip}
          tooltipLabel={labelTooltip}
        />
      }
    >
      <Form.Item
        name={`${fieldPrefix}${String(field)}`}
        key={key}
        rules={
          readOnly
            ? []
            : [
                ...rules,
                basicMandatoryRule({
                  field,
                  emptyErrorMessage,
                  constraints: { isRequired: required },
                }),
              ]
        }
        className={className}
      >
        <DatePicker
          getPopupContainer={
            inModal
              ? formHelper.getInModalPopupContainer
              : formHelper.getPopupContainer
          }
          picker={picker}
          disabled={readOnly ?? disabled}
          id={key}
          placeholder={inputPlaceholder}
          showTime={false}
          disabledDate={(current) => {
            return disabledDate
              ? disabledDate(DateTime.fromJSDate(current.toDate()))
              : DateTime.fromJSDate(current.toDate()) > DateTime.now();
          }}
          format={dateFormat}
          className={fieldClassName}
          data-test-type="input-date"
          data-test={field}
        />
      </Form.Item>
    </FloatingLabel>
  );
};

export default DatePickerFormField;
