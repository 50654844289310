import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/core/layouts/PageLayout";
import { userSupportEmail } from "@/main";

const SsoLoginScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      container={false}
      contentInCard
      bodyClassName="d-flex align-items-center justify-content-center h-100"
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h1 className="mt-16 mb-32 text-center">
          {t("deeplink.auth.sso.title")}
        </h1>
        <div className="text-center mb-16">
          {t("deeplink.auth.sso.description.wrongConfiguration")}
        </div>
        <div className="text-center mb-16">
          {t("deeplink.auth.sso.description.consequence")}
        </div>
        <div className="text-center font-weight-bold mb-16">
          {t("deeplink.closeTab")}
        </div>
        <div className="text-center">
          {t("deeplink.support")}
          <a href={userSupportEmail}>{userSupportEmail}</a>.
        </div>
      </div>
    </PageLayout>
  );
};

export default SsoLoginScreen;
